import "./resFilterOptions.scss";
import {reservationFilterOptions} from "features/reservations/reservationFiltersConstant";
import ResSingleSelect from "features/reservations/ResSingleSelect";
import {useState} from "react";

const FilterOptions = ({ selectedFilters, onFiltersChange,resetPage }) => {
    const [filters, setFilters] = useState({
        idPlatForm: 0,
        bookingMonth: new Date().toISOString().slice(0, 7),
        checkOutMonth: '',
        bookingStatus: '',
        billingMode: '',
    });

    const handleFiltersChange = (value, id) => {
        const updatedFilters = {
            ...filters,
            [id]: value,
        };
        setFilters(updatedFilters);
        onFiltersChange(updatedFilters);  // Send updated filters to parent
        resetPage(0); // To reset the current page in pagination
    };

    return (
        <div>
            <div className="form-button-container">
                <div className="formGrid">
                    <div className="filter-field-container">
                        {reservationFilterOptions.map((field) => {
                            return (
                                <div className="form-grid-item">
                                    <div className="mul-item">
                                        <div className="mul-form-label">{field.label}</div>
                                        <ResSingleSelect
                                            value={field.id === 'bookingMonth' ? selectedFilters.bookingMonth : undefined}
                                            options={field.options}
                                            onChange={(event) =>
                                                handleFiltersChange(event.target.value, field.id)
                                            }
                                            sx={{
                                                backgroundColor: "white",
                                                width: field.id === 'billingMode' ? "250px" : "190px",
                                            }}
                                            placeholder={field.placeholder}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default FilterOptions;
