import { Grid, Tooltip } from "@mui/material";
import Table from "components/Table";
import { useEffect, useState } from "react";
import { createSearchParams } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import "./TableContainer.scss";
import { APP_GOOGLE_SEM_API_ENDPOINT } from "constants/api_urls";
import EditIcon from "../../../../assets/images/ActionIcons/Edit.svg";
import {getUserToken} from "../../../../utils/functions/userToken";

const SearchTermTableContainer = ({
  isChartCollapsed,
  setIsChartCollapsed,
  onEditClick,
  onEditCampaign,
    isAccountWritable,
}) => {
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isPaginationEnabled, setIsPaginationEnabled] = useState(false);
  const [paginationObject, setPaginationObject] = useState({});
  const [pageSize, setPageSize] = useState(15);
  const [pageIndex, setPageIndex] = useState(0);
  const [sortingState, setSortingState] = useState([]);
  const [loading, setLoading] = useState(true);

  const token = getUserToken() || "";
  const { account_id = "" } = useSelector(
    (state) => state?.account?.account || {}
  );
  const {hotel_id = [], level } = useSelector(state =>state?.account)
  const { currentDateRange = {}, comparitionDateRange = {}, compareWith } = useSelector((state) => state?.calender);
  const {
    searchTermReportingSegmentList = [],
    campaign = [],
  } = useSelector((state) => state?.searchAds);

  useEffect(() => {
    const params = {
    account_id,
    level,
    "campaign_id[]": campaign?.map((c) => c.id),
    "segment_list[]": searchTermReportingSegmentList,
    start_date: format(
      currentDateRange?.startDate || new Date(),
      "yyyy-MM-dd"
    ),
    end_date: format(currentDateRange?.endDate || new Date(), "yyyy-MM-dd"),
    "hotel_id[]": hotel_id,
    ...(comparitionDateRange?.startDate && comparitionDateRange?.endDate
      ? {
          compare_start_date: format(
            comparitionDateRange?.startDate,
            "yyyy-MM-dd"
          ),
          compare_end_date: format(
            comparitionDateRange?.endDate,
            "yyyy-MM-dd"
          ),
        }
      : {}),
    };

    if (account_id) {
      setLoading(true);
      axios
        .post(
          `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/search-terms/reporting/`, createSearchParams(
            params
          ),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const data = res?.data;
          setIsPaginationEnabled(data?.paginator_enabled);
          setPaginationObject(data);
          const sortedColumns = [
            ...data?.columns?.filter(
              (col) => col?.type !== "METRICS" && col?.type === "SEGMENT"
            ),
            ...data?.columns?.filter(
              (col) => col?.type !== "METRICS" && col?.type !== "SEGMENT"
            ),
            ...data?.columns?.filter((col) => col?.type === "METRICS"),
          ];
          const mappedColumns = sortedColumns?.map((col, index) => {
            let renderCell = ({ row, cell }) => {
              let style = {};
              if (row.id === "0" && cell.column.id === "0") {
                style = {
                  fontFamily: "Inter, sans-serif",
                  fontSize: "12px",
                  fontWeight: "600",
                };
              }
              return (
                  compareWith && col?.type !== "SEGMENT" ? <Tooltip
                      title={
                        col.type === "BUDGET_SPENT"
                            ? row?.original?.budget_remaining
                            : `${row?.original?.[
                                `${cell.column?.columnDef?.accessorKey}_compare`
                                ]} ${row?.original?.[`${cell.column?.columnDef?.accessorKey}_compare_percent`]}%`
                      }
                  >
                    <span style={style}>{cell.getValue()}</span>
                  </Tooltip>: <span style={style}>{cell.getValue()}</span>
              );
            };

            if (col.id === "idcampaign") {
              renderCell = ({ cell, row }) => {
                const isFirstRow = row.index === 0;
                return (
                    <span key={index} className="campaign-cell">
                     <span>{cell.getValue()}</span>{" "}
                      {!isFirstRow && isAccountWritable && (
                          <span
                              className={"edit-icon-table-row"}
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                  onEditCampaign(row.original.idcampaign_id)
                              }
                          >
                         <img src={EditIcon} alt="Edit" />
                           </span>
                      )}
                     </span>
                );
              };
            }
            return {
              id: index.toString(),
              accessorKey: col.id,
              Header: <Tooltip title={col?.description}><span>{col?.label}</span></Tooltip>,
              header: col?.label,
              Cell: renderCell,
              isSelected: true,
              type: col?.type,
            };
          });

          const formatRows = (row, id) => {
            const metricsRows = Object.keys(row?.metrics_list || {}).reduce(
              (acc, curr) => {
                return {
                  ...acc,
                  [curr]: row?.metrics_list?.[curr]?.value ?? "-",
                  [`${curr}_compare`]:row?.metrics_list?.[curr]?.compared_value,
                  [`${curr}_compare_percent`]: row?.metrics_list?.[curr]?.compare_percent
                };
              },
              {}
            );

            const segmentsRows = Object.keys(row?.segment_list || {}).reduce(
              (acc, curr) => {
                return {
                  ...acc,
                  [curr]: row?.segment_list?.[curr]?.label ?? "-",
                  [curr + "_id"]: row?.segment_list?.[curr]?.value ?? "-",
                };
              },
              {}
            );

            const infoRows = Object.keys(row?.info_list || {}).reduce((acc,curr) => {
              const infoObject = row?.info_list?.[curr];
              return {
                ...acc,
                [curr]: data?.[infoObject?.attribute]?.[infoObject?.value]?.[infoObject?.info_path]
              }
            }, {})

            return {
              id,
              ...segmentsRows,
              ...infoRows,
              ...metricsRows,
              bid: row?.bid,
              budget: row?.budget,
              budget_spent: "-",
            };
          };

          const rowsToDisplay = data?.row_list?.map((row, ind) =>
            formatRows(row, ind + 1)
          );

          setColumns(mappedColumns);
          setLoading(false);
          setSelectedColumns(mappedColumns);
          setTableData([
            {
              [mappedColumns?.[0]?.accessorKey]: "TOTAL",
              ...formatRows(data?.total_row, "0"),
            },
            ...rowsToDisplay,
          ]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(campaign),
    token,
    account_id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(currentDateRange),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(comparitionDateRange),
    level,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(sortingState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(searchTermReportingSegmentList),
    pageSize,
    pageIndex,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(hotel_id),
  ]);

  return (
    <>
      <Grid sx={{ marginBlock: "10px", maxWidth: "100%" }}>
        <Table
          page="searchAdsSearchTerm"
          rows={tableData}
          columns={columns}
          selectedColumns={selectedColumns}
          handleColumnSelectionChange={(columns) => {
            setSelectedColumns(columns);
          }}
          selectedSegments={searchTermReportingSegmentList}
          isChartCollapsed={isChartCollapsed}
          setIsChartCollapsed={setIsChartCollapsed}
          maxHeight={isChartCollapsed ? "calc(100vh - 255px)" : "400px"}
          onEditClick={() => {
            onEditClick();
          }}
          paginationData={paginationObject}
          isPaginationEnabled={isPaginationEnabled}
          handlePagination={(pagination) => {
            setPageIndex(pagination?.pageIndex);
            setPageSize(pagination?.pageSize);
          }}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          handleSorting={(sorting) => {
            setSortingState(sorting);
          }}
          isLoading={loading}
        />
      </Grid>
    </>
  );
};

export default SearchTermTableContainer;
