import MetricBase from "models/metrics/MetricBase";
import i18n from "i18next";
import CurrencyUnit from "models/metrics/unit/CurrencyUnit";
import PerformanceGroup from "models/groups/PerformanceGroup";

class MetricSpent extends MetricBase {
    constructor() {
        super({
            field: "metric_spent",
            defaultVisibility: false,
            unit: new CurrencyUnit(),
            chartName: i18n.t("metrics.tripadvisor.spent.chartName"),
            serieLabel: i18n.t("metrics.tripadvisor.spent.serieLabel"),
            tooltip: i18n.t("metrics.tripadvisor.spent.tooltip"),
            group: new PerformanceGroup(),
        });
    }
}

export default MetricSpent;