import React, { useState } from "react";
import { DatePicker } from "../../components/DatePicker";
import { Box, Typography } from "@mui/material";
import "./homepage.scss";
import { DecreaseIcon, IncreaseIcon } from "../../assets/images/Icons";
import ChartContainer from "../../features/homepage/chartContainer";
import TableContainer from "features/homepage/TableContainer";
import { useSelector } from "react-redux";
import { mapper } from "components/DatePicker/DatePicker";
import DefaultLayout from "layout/defaultLayout";
import PageTitles from "../../components/PageTitles";

const Homepage = () => {
  const { calender } = useSelector((state) => state);
  const [isChartCollapsed, setIsChartCollapsed] = useState(false);
  const metrics = [
    {
      label: "Cost",
      value: "27.1K",
      percentage: "1.2",
      direction: "down",
      extra: "",
      color: "#61D5A9",
    },
    {
      label: "Generated",
      value: "27.1K",
      percentage: "1.2",
      direction: "down",
      extra: "",
      color: "#DADADA",
    },
    {
      label: "Clicks",
      value: "27.1K",
      percentage: "1.2",
      direction: "down",
      extra: "",
      color: "#CBCDFC",
    },
    {
      label: "CPC",
      value: "27.1$",
      percentage: "1.1",
      direction: "down",
      extra: "",
      color: "#FDBFB7",
    },
    {
      label: "Conversions",
      value: "120",
      percentage: "4.1",
      direction: "up",
      extra: "",
      color: "#87B9FB",
    },
    {
      label: "ROAS",
      value: "27.1",
      percentage: "5.2",
      direction: "up",
      extra: "",
      color: "#EBA9E3",
    },
  ];

  return (
    <>
      <DefaultLayout page={null}>
        <Box sx={{ display: "flex", justifyContent: "space-between",marginRight:"18px" }}>
          <PageTitles
              pageTitle="Digital Marketing Platform"
          />
          <DatePicker />
        </Box>
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            paddingTop: 2,
            paddingInline: "18px",
          }}
        >
          <Box sx={{ display: "flex", marginTop: 2 }}>
            {metrics.map((item, i) => {
              return (
                <Box
                  sx={{
                    marginRight: i === metrics.length - 1 ? "0px" : "18px",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    flex: 1,
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "0px 0px 10px 10px",
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      height: "5px",
                      backgroundColor: item.color,
                    }}
                  >
                    &nbsp;
                  </div>
                  <div style={{ padding: "10px", paddingInline: "20px" }}>
                    <Typography className="score-title">
                      {" "}
                      {item.label}{" "}
                    </Typography>
                    <Typography className="score-value">
                      {" "}
                      {item.value}{" "}
                    </Typography>
                    <Box>
                      {" "}
                      <span className="score-direction">
                        {item?.direction === "up" ? (
                          <IncreaseIcon />
                        ) : (
                          <DecreaseIcon />
                        )}{" "}
                      </span>{" "}
                      <span
                        className={
                          item?.direction === "up"
                            ? "score-percentage score-increase"
                            : "score-percentage score-decrease"
                        }
                      >
                        {" "}
                        {item.percentage}%{" "}
                      </span>
                      {i === 0 && (
                        <span style={{ fontSize: "8px" }}>
                          {" "}
                          vs {mapper[calender?.comparitionSelection] ?? ""}{" "}
                        </span>
                      )}
                    </Box>
                  </div>
                </Box>
              );
            })}
          </Box>

          <Box
            sx={
              {

              }
            }
          >
            <div
              style={{
                display: isChartCollapsed ? "none" : "block",
              }}
            >
              <ChartContainer />
            </div>
            <TableContainer
              setIsChartCollapsed={setIsChartCollapsed}
              isChartCollapsed={isChartCollapsed}
            />
          </Box>
        </Box>
      </DefaultLayout>
    </>
  );
};
export default Homepage;
