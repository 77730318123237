import React, {useState} from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    TextField,
    MenuItem,
    Typography,
    Box,
    Snackbar,
    Alert,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './UserInfoAccordion.scss';
import CustomButtonWithIcon from "../../../../components/CustomButtonWithIcon/Buttons";
import CustomFormControl from "../../../alert-center/Dropdown/CustomFormControl";

const UserInfoAccordion = ({expanded, handleChange, user, mode, onClose, handleSubmit}) => {
    const [username, setUsername] = useState(user?.username || '');
    const [firstname, setFirstname] = useState(user?.firstname || '');
    const [lastname, setLastname] = useState(user?.lastname || '');
    const [language, setLanguage] = useState(user?.language || 'en');
    const [status, setStatus] = useState(user?.status === 'Active' || mode === 'add');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const isEditMode = mode === 'edit';

    const onSubmit = async () => {
        if (!username || !firstname || !lastname || !language) {
            setErrorMessage('Please fill all the fields');
            return;
        }

        setLoading(true);
        await handleSubmit({
            userData: {
                firstname,
                lastname,
                language,
                status: status ? 1 : 0,
            },
            username,
            isEditMode,
        });

        setLoading(false);
    };

    return (
        <Accordion
            className="user-accordion"
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            disableGutters
            sx={{
                border: '1px solid #61D5A9',
                borderRadius: '8px !important',
                marginBottom: '10px',
                boxShadow: 'none',
                '&::before': {
                    display: 'none', // Hides the default outline when not expanded
                },
                '&.Mui-expanded': {
                    borderBottomLeftRadius: '8px !important',
                    borderBottomRightRadius: '8px !important',
                },
            }}
        >
            <AccordionSummary expandIcon={mode === 'edit' ? <ExpandMoreIcon/> : ''} sx={{
                borderBottom: expanded === 'panel1' ? '1px solid #61D5A9' : 'none',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                height: '42px',
                minHeight: '42px',
                '&.Mui-expanded': {
                    minHeight: '42px',
                    height: '42px',
                },
                '.MuiAccordionSummary-content': {
                    margin: 0,
                }
            }}>
                <Typography sx={{
                    fontWeight: '400',
                    fontSize: '12px',
                    padding: '4px',
                    color: '#373F41',
                    fontFamily: 'Inter',
                    lineHeight: '14.52px'
                }}>
                    {mode === 'edit' ? `USER INFORMATION:` : 'NEW USER'}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{
                borderBottomLeftRadius: expanded === 'panel1' ? '8px' : 0,
                borderBottomRightRadius: expanded === 'panel1' ? '8px' : 0,
                backgroundColor: '#F5F5F5',
                height: '100%',
            }}>
                <Grid container spacing={2} sx={{padding: '4px'}}>

                    <Grid item xs={12}>
                        <Typography variant="body2" className="user-accordion-input-label">
                            USERNAME:
                        </Typography>
                        <TextField
                            placeholder="Email"
                            value={username}
                            fullWidth
                            variant="outlined"
                            disabled={isEditMode || loading}
                            onChange={(e) => setUsername(e.target.value)}
                            className="user-accordion-text-field"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body2" className="user-accordion-input-label">
                            FIRST NAME:
                        </Typography>
                        <TextField
                            placeholder="Enter First Name"
                            value={firstname}
                            fullWidth
                            variant="outlined"
                            disabled={loading}
                            onChange={(e) => setFirstname(e.target.value)}
                            className="user-accordion-text-field"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body2" className="user-accordion-input-label">
                            LAST NAME:
                        </Typography>
                        <TextField
                            placeholder="Enter Last Name"
                            value={lastname}
                            fullWidth
                            variant="outlined"
                            disabled={loading}
                            onChange={(e) => setLastname(e.target.value)}
                            className="user-accordion-text-field"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2" className="user-accordion-input-label">
                            LANGUAGE:
                        </Typography>
                        <CustomFormControl
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                            displayEmpty
                            disabled={loading}
                            className="user-accordion-select-dropdown"
                        >
                            <MenuItem value="">
                                <span style={{
                                    fontSize: "12px",
                                    color: "#bab7b7"
                                }}>
                                    Select Language
                                </span>
                            </MenuItem>
                            <MenuItem value="en">
                                <span style={{
                                    fontSize: "12px",
                                    color: "#373F41"
                                }}>
                                    English
                                </span>
                            </MenuItem>
                            <MenuItem value="fr">
                                <span style={{
                                    fontSize: "12px",
                                    color: "#373F41"
                                }}>
                                    French
                                </span>
                            </MenuItem>

                        </CustomFormControl>

                    </Grid>

                    <Grid item xs={6}>
                        <Typography className="user-accordion-input-label">
                            STATUS:
                        </Typography>
                        <CustomFormControl
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            displayEmpty
                            disabled={loading}
                            className="user-accordion-select-dropdown"
                        >
                            <MenuItem value="">
                                <span style={{
                                    fontSize: "12px",
                                    color: "#bab7b7"
                                }}>Select Status</span>
                            </MenuItem>
                            <MenuItem value="true">
                                <span style={{
                                    fontSize: "12px",
                                    color: "#373F41"
                                }}>Active</span></MenuItem>
                            <MenuItem value="false"><span style={{
                                fontSize: "12px",
                                color: "#373F41"
                            }}>Inactive</span></MenuItem>
                        </CustomFormControl>

                    </Grid>
                </Grid>

                <Box display="flex" justifyContent="start"
                     sx={{gap: 1, mt: 2, padding: '8px', paddingTop: '14px', marginBottom: '-10px'}}>
                    <CustomButtonWithIcon
                        color="#b0acac"
                        hoverColor="#12794F"
                        titleColor="#373F41"
                        hoverTitleColor={"white"}
                        width={"120px"}
                        height={"24px"}
                        borderRadius="20px"
                        textStyle={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '14.52px',
                            textAlign: 'center',
                        }}
                        onClick={onSubmit}

                    >
                        {isEditMode ? 'Save' : 'Add'}
                    </CustomButtonWithIcon>

                    <CustomButtonWithIcon
                        color="#b0acac"
                        hoverColor="#12794F"
                        titleColor="#373F41"
                        hoverTitleColor={"white"}
                        width={"120px"}
                        height={"24px"}
                        borderRadius="20px"
                        textStyle={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '14.52px',
                            textAlign: 'center',
                        }}
                        onClick={onClose}

                    >
                        Back
                    </CustomButtonWithIcon>
                </Box>

                {/* Snackbar Messages */}
                {errorMessage && (
                    <Snackbar open autoHideDuration={4000} onClose={() => setErrorMessage('')}>
                        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{width: '100%'}}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                )}
                {successMessage && (
                    <Snackbar open autoHideDuration={4000} onClose={() => setSuccessMessage('')}>
                        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{width: '100%'}}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default UserInfoAccordion;
