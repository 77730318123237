import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
} from "@mui/material";
import IconWrapper from "components/IconWrapper";
import close from "assets/images/close.svg";
import TextInput from "components/TextInput";
import "./tableValueEditor.scss";
import CustomButton from "components/Button";
import { useEffect, useState } from "react";
import axios from "axios";
import { api_urls_charts } from "../chartContainer/utils";
import {getUserToken} from "../../../utils/functions/userToken";
const TableValueEditor = ({ open, onClose, bid }) => {
  const token = getUserToken() || "";
  const bidHistoryURL = `${api_urls_charts["skyScanner"]}${bid?.bid_history_path}`;

  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (open) {
      axios
        .get(bidHistoryURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const data = res?.data;
          setColumns(data?.columns_list);
          setTableData(data?.history_list);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [bid, bidHistoryURL, open, token]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "300px",
          maxHeight: "400px",
          height: "fit-content",
          boxShadow: "0px 5px 8px 0px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
          paddingBottom: "12px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 24px 0px",
        }}
      >
        <span className="dialogTitle">Bid History</span>
        <span>
          {" "}
          <IconWrapper onClick={onClose} icon={close} />{" "}
        </span>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <TextInput
            sx={{
              width: "100px",
              height: "22px",
              borderRadius: "10px",
              padding: "4px",
              flexShrink: 0,
            }}
            numberUnit=""
            placeholder="Enter value"
          />
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flexShrink: 0,
              marginLeft: "10px",
            }}
          >
            <CustomButton
              sx={{ width: "80px" }}
              title="Apply"
              variant={"filled"}
              onClick={() => {}}
            />
          </DialogActions>
        </Box>
        <Table classname="table-value-editor">
          <tr>
            {columns
              ?.filter((c) => c?.column_id !== "status")
              ?.map((col) => (
                <th key={col?.column_id}>{col?.column_label}</th>
              ))}
          </tr>
          {tableData.slice(0, 8).map((item) => {
            const row = item?.history_data;
            return (
              <tr>
                {columns
                  ?.filter((c) => c?.column_id !== "status")
                  ?.map((col) => (
                    <td>{row?.[col.column_id]}</td>
                  ))}
              </tr>
            );
          })}
        </Table>
      </DialogContent>
    </Dialog>
  );
};
export default TableValueEditor;
