import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import DefaultLayout from "layout/defaultLayout";
import PageTitles from "../../components/PageTitles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateAccount } from "../../store/slices/account/accountSlice";

const TransitionPage = () => {
    const {accounts} = useSelector(state => state?.account)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (accounts.length > 0) {
            const favoriteAccount =
                accounts.find((account) => account?.['is_favorite']) ||
                accounts[accounts.length - 1];
            dispatch(updateAccount(favoriteAccount));
            navigate(`/home/${favoriteAccount.account_id}`);
        }
    }, [accounts, navigate, dispatch]);

    return (
        <DefaultLayout>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginRight: "18px" }}>
                <PageTitles pageTitle="Digital Marketing Platform" />
            </Box>

            {accounts.length === 0 && (
                <Typography sx={{ mt: 4, textAlign: "center" }}>
                    No account available, please contact support.
                </Typography>
            )}

            {accounts.length > 0 && (
                <Typography sx={{ mt: 4, textAlign: "center" }}>
                    Accounts Loaded Successfully
                </Typography>
            )}
        </DefaultLayout>
    );
};

export default TransitionPage;
