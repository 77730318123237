import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MetricSpent from "../../models/metrics/metasearchMetrics/tripadvisor-meta/performance/MetricSpent";

const TestTranslationsPage = () => {
    const { i18n } = useTranslation();
    const [currencyLabel, setCurrencyLabel] = useState("");
    const [currencySymbol, setCurrencySymbol] = useState("");

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const metric = new MetricSpent();
    useEffect(() => {
        const context = { currencyCode: "EUR" };
        metric.unit.setContext(context);
        setCurrencyLabel(metric.unit.getLabel());
        setCurrencySymbol(metric.unit.getSymbol());
    }, [metric.unit]);

    return (
        <div style={{ padding: "20px" }}>
            <h1>{metric.chartName}</h1>
            <p>{metric.serieLabel}</p>
            <p>{metric.tooltip}</p>

            <h2>Test currency</h2>
            <p>
                <strong>Label :</strong> {currencyLabel}
            </p>
            <p>
                <strong>Symbol :</strong> {currencySymbol}
            </p>


            <div style={{ marginTop: "20px" }}>
                <button onClick={() => changeLanguage("en")}>English</button>
                <button onClick={() => changeLanguage("fr")}>Français</button>
            </div>
        </div>
    );
};

export default TestTranslationsPage;
