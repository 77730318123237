import React, {useEffect, useState} from "react";
import {Box, Grid} from "@mui/material";
import TopHotelsTable from "./TopHotelsTable";
import TopHotelsChart from "./TopHotelsChart";
import { TopHotelsAvailableMetrics } from "../Utils/AvailableMetrics";
import { COLORS } from "../Utils/Colors";
import "./TopHotelsComponent.scss";
import CustomDrawerComponent from "../Utils/CustomDrawerComponent";
import { formatValue } from "../Utils/FormatMetricValue";

function TopHotelsComponent({ selectedOption, handleDropdownChange, topHotelsMetrics = {}, errorMessage = null }) {
    const [tableMetrics, setTableMetrics] = useState(["clicks_kpi", "generated_kpi", "roas_kpi"]);
    const [lastOption, setLastOption] = useState("clicks");

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1200 && window.innerWidth < 1650) {
                setTableMetrics(["clicks_kpi", "generated_kpi", null]);
            } else {
                setTableMetrics(["clicks_kpi", "generated_kpi", "roas_kpi"]);
            }
        };

        // Set the initial value on component mount
        handleResize();

        // Attach event listener to handle screen resizing
        window.addEventListener("resize", handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleTableMetricChange = (event, index) => {
        const newMetric = event.target.value;
        const newMetrics = [...tableMetrics];
        newMetrics[index] = newMetric;
        setTableMetrics(newMetrics);
    };

    const selectedHotels = topHotelsMetrics[`top_hotel_by_${lastOption}`] || [];
    const isLoading = Object.keys(topHotelsMetrics).length === 0;

    const chartData = selectedHotels.map((hotel, index) => ({
        name: hotel.hotel_name,
        value: parseFloat(hotel[`${lastOption}_kpi`] || 0),
        fill: COLORS[index % COLORS.length],
        maxValue: topHotelsMetrics[`total_${lastOption}_kpi`]
    }));

    const maxValue = topHotelsMetrics[`total_${lastOption}_kpi`] ?? 0;

    return (
        <Box
            className="top-hotels-component"
            position="relative"
            style={{ overflow: "hidden" }}
        >
            <CustomDrawerComponent isLoading={isLoading} errorMessage={errorMessage} />
            <Grid container spacing={2} className={`top-hotels-container ${Object.keys(topHotelsMetrics).length === 0 ? 'loading' : ''}`}>
                <TopHotelsTable
                    selectedHotels={selectedHotels}
                    tableMetrics={tableMetrics}
                    formatValue={formatValue}
                    handleTableMetricChange={handleTableMetricChange}
                    TopHotelsAvailableMetrics={TopHotelsAvailableMetrics}
                    COLORS={COLORS}
                    selectedOption={selectedOption}
                    handleDropdownChange={handleDropdownChange}
                    currencySymbol={topHotelsMetrics.currency_symbol}
                />
                {!(Object.keys(topHotelsMetrics).length === 0) && (
                    <TopHotelsChart
                        topHotelsMetrics={topHotelsMetrics}
                        chartData={chartData}
                        lastOption={lastOption}
                        setLastOption={setLastOption}
                        currencySymbol={topHotelsMetrics.currency_symbol}
                        maxValue={maxValue}
                    />
                )}
            </Grid>
        </Box>
    );
}

export default TopHotelsComponent;