  import React, { useState, useEffect, useCallback } from 'react';
  import { Grid, TextField, Select, MenuItem, Button, Typography, Checkbox, FormControl, FormHelperText, Box, ListItemText  } from '@mui/material';
  import { validateReportForm } from '../../validation/reportValidation';
  import { sanitizeString } from '../../../../utils/functions/sanitize';
  import { fetchService } from '../../Services/fetchService';
  import Tags from "@yaireo/tagify/dist/react.tagify";
  import { fetchTimezones, fetchFtp, fetchWhiteLabelEmail } from '../../Services/apiServices';
  import {APP_REPORT_CENTER_API_ENDPOINT} from "../../../../constants/api_urls";
  import { useParams } from "react-router-dom";
  import CustomButtonWithIcon from "components/CustomButtonWithIcon/Buttons";
  import DmpIcons from "components/DmpIcons/icons";
  import DateInputWithCustomIcon from "components/DateInputWithCustomIcon/DateInputWithCustomIcon";
  import dayjs from 'dayjs';
  import {Chip} from "@mui/material";
  import CustomFormControl from "../../../alert-center/Dropdown/CustomFormControl";
  import { handleApiResponse } from '../../Services/handleApiResponse'; 
  import '../../reportCenter.scss'

  const SpFunnelTripAdvisor = ({ formData,  showSnackbar, onClose, isEdit= false }) => {
    const [reportRecipients, setreportRecipients] = useState([]);
    const [timezones, setTimezones] = useState([]);
    const [ftp, setftp] = useState([]);
    const [whiteLabelEmail, setWhiteLabelEmail] = useState([]); 
    const [errors, setErrors] = useState({});
    const [showAdditionalSettings, setShowAdditionalSettings] = useState(false);
    const [dayOfWeek, setDayOfWeek] = useState(formData?.day || []);
    const { idaccount } = useParams();
    const DayOfWeek= ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THRUSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']

    useEffect(() => {
      if (formData?.recipients) {
          setreportRecipients(
              formData.recipients.map((email) => ({ value: email }))
          );
      }
      if (formData?.day) {
        setDayOfWeek(formData.day || []);
      }
    }, [formData]);

    const [reportData, setReportData] = useState({
      name: formData?.name || "",
      report_id: formData?.report_id || "",
      account: idaccount,
      date_range: formData?.date_range || "",
      period_length: formData?.period_length || "",
      delivery_frequency: formData?.delivery_frequency || "",
      day_month: formData?.day_month || "",
      day : formData?.day || [],
      time_of_day: formData?.time_of_day || "",
      time_zone: formData?.time_zone || "",
      export_method: formData?.export_method || "",
      delevery_method_ftp:formData?.delevery_method_ftp || "",
      folder: formData?.folder || "",
      recipients :formData?.recipients || [],
      subject: formData?.subject || "",
      export_name: formData?.export_name || "",
      export_file_type: formData?.export_file_type || "",
      attached_file: formData?.attached_file || "",
      whitelabel_email : formData?.whitelabel_email || "",
    });

    const handleCreateReport = async () => {
      const sanitizedData = {
        report_id: reportData.id,
        account: parseInt(idaccount, 10),
        name: sanitizeString(reportData.name),
        date_range: reportData.date_range,
        delivery_frequency: reportData.delivery_frequency,
        period_length:  reportData.period_length,
        custom_start_date:reportData.custom_start_date,
        custom_end_date: reportData.custom_end_date,
        day_month: reportData.day_month,
        day : reportData.day,
        time_of_day: reportData.time_of_day,
        time_zone: reportData.time_zone,
        export_method: reportData.export_method,
        delevery_method_ftp:reportData.delevery_method_ftp,
        folder: reportData.folder,
        recipients :reportData.recipients,
        subject: reportData.subject,
        export_name: reportData.export_name,
        export_file_type: reportData.export_file_type,
        attached_file: reportData.attached_file,
        whitelabel_email : reportData.whitelabel_email, 
      };
     
      const validationErrors = validateReportForm(sanitizedData);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);  
      }

      try {
        const API_URL = isEdit ? APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/sp-funnel-trip-advisor/edit" : APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/sp-funnel-trip-advisor/add";
        const API_METHOD = isEdit ? "PATCH" : "POST";
        const response = await fetchService(API_URL, API_METHOD, JSON.stringify(sanitizedData));
        handleApiResponse(response, { isEdit, showSnackbar, setErrors, onClose});

        
      } catch (error) {
        if (showSnackbar) {
          showSnackbar("Something went wrong while creating the report.", "error");
        }
        console.error('Error creating report:', error);
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        const fetchServiceInstance = fetchService;

        try {
          // Parallel API calls
          const [timezones, ftpData, whiteLabelEmails] = await Promise.all([
            fetchTimezones(fetchServiceInstance, setErrors),
            fetchFtp(fetchServiceInstance, idaccount, setErrors),
            fetchWhiteLabelEmail(fetchServiceInstance, idaccount, setErrors),
          ]);

          // Update state only for successful calls
          setTimezones(timezones || []);
          setftp(ftpData || []);
          setWhiteLabelEmail(whiteLabelEmails || []);
        } catch (error) {
          console.error('Error during data fetching:', error);
        }
      };

      fetchData();
    }, [idaccount]);

    useEffect(() => {
      setReportData(prev => ({
          ...prev,
          ...formData,
      }));
  }, [formData]);

    useEffect(() => {
      if (!reportData.level) {
        setReportData((prevData) => ({ ...prevData, level: 'ACCOUNT' }));  
      }
      if (!reportData.export_method) {
        setReportData((prevData) => ({ ...prevData, export_method: "EMAIL" })); 
      }
      if (!reportData.export_file_type) {
        setReportData((prevData) => ({ ...prevData, export_file_type: "csv" }));
      }
      if (!reportData.attached_file) {
        setReportData((prevData) => ({ ...prevData, attached_file: "ZIP_COMPRESSION" })); 
      }
      if (!reportData.delivery_frequency) {
        setReportData((prevData) => ({ ...prevData, delivery_frequency: "ONCE" })); 
      }
      if (!reportData.date_range) {
        setReportData((prevData) => ({ ...prevData, date_range: "CURRENT_MONTH" })); 
      }
      
    }, [reportData, setReportData]);

    const handleRecipientsChange = useCallback((e) => {
      let parsedValue = [];
      try {
          parsedValue = JSON.parse(e.detail.value);
      } catch (error) {
          console.error("Parsing error:", error);
      }

      const updatedRecipients = parsedValue
          .map(tag => tag.value.split(',').map(email => email.trim()))
          .flat();

      setReportData((prevData) => ({
          ...prevData,
          recipients: updatedRecipients,
      }));
      setreportRecipients(updatedRecipients);
    }, [setReportData]);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setReportData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    
    };

    const handleDelete = (type, valueToDelete) => {
      if (type === "day") {
        const updatedColumns = dayOfWeek.filter((value) => value !== valueToDelete);
        setDayOfWeek(updatedColumns);
        setReportData((prev) => ({
          ...prev,
          day: updatedColumns,
        }));
      } 
      
    };

  const handleToggleSettings = () => {
      setShowAdditionalSettings(!showAdditionalSettings);
    };   

    return (
      <div className="report-form-container">
        <Grid item xs={12}>
          <Grid item xs={10} container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={8}>
                <TextField
                    fullWidth
                    name="name"
                    className="form-input"
                    placeholder='Report Name:'
                    value={reportData.name}
                    onChange={handleInputChange}
                    error={!!errors.name} 
                    helperText={errors.name || ''}
                  />
                </Grid>
            </Grid>
          
            <Grid container item xs={12}>
            <Grid item xs={9}>
              <Grid item xs={5}>
                <FormControl fullWidth error={!!errors.date_range}>
                  <Typography variant="body2">Date Range</Typography>
                  <Select
                    value={reportData.date_range}
                    name="date_range"
                    className="form-select"
                    onChange={(e) => {
                      handleInputChange(e);
                      setReportData({ ...reportData, date_range: e.target.value });
                    }}
                  >
                    <MenuItem value="CURRENT_MONTH">Current Month</MenuItem>
                    <MenuItem value="LAST_MONTH">Last Month</MenuItem>
                    <MenuItem value="LAST_7_DAYS">Last 7 Days</MenuItem>
                    <MenuItem value="ONE_DAY">One Day</MenuItem>
                    <MenuItem value="CURRENT_YEAR">Current Year</MenuItem>
                    <MenuItem value="LAST_YEAR">Last Year</MenuItem>
                    <MenuItem value="CUSTOM">Custom</MenuItem>
                  </Select>
                  {errors.date_range && <FormHelperText>{errors.date_range}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
            {reportData.date_range === 'CUSTOM' && ( 
                  <>
                   <Grid container item xs={12}>
                      <Grid item xs={2} className='report-custom-date'>
                        <FormControl fullWidth >
                          <Typography  variant="body2" className='custom-date-from'>Date from</Typography>
                            <DateInputWithCustomIcon
                                format={'DD/MM/YYYY'}
                                value={dayjs(reportData.custom_start_date)}
                                onChange={(startDate) => {
                                  const date = new Date(startDate);
                                  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                                  const formattedDate = date.toISOString().split('T')[0]; 
                                  setReportData({ ...reportData, custom_start_date: formattedDate });
                                }}
                                icon={DmpIcons.ActionsIcons.CalendarIcon}
                            />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} className='report-custom-date'>
                        <FormControl fullWidth>
                          <Typography variant="body2" className='custom-date-to' >Date to</Typography>
                          <DateInputWithCustomIcon
                                format={'DD/MM/YYYY'}
                                value={dayjs(reportData.custom_end_date)}
                                onChange={(endDate) => {
                                  const date = new Date(endDate);
                                  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                                  const formattedDate = date.toISOString().split('T')[0]; 
                                  setReportData({ ...reportData, custom_end_date: formattedDate });
                                }}
                                icon={DmpIcons.ActionsIcons.CalendarIcon}
                            />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                )}
              {reportData.date_range === 'ONE_DAY' && (
                <>
                <Grid item xs={9}>
                  <Grid item xs={5}>
                      <FormControl fullWidth error={!!errors.period_length}>
                        <Typography variant="body2">Day</Typography>
                        <Select
                          fullWidth
                          className="form-select"
                          value={reportData.period_length || 1}
                          onChange={(e) => setReportData({ ...reportData, period_length: String(e.target.value) })}
                          displayempty="true"
                        >
                          <MenuItem value="" disabled>
                            Select Day
                          </MenuItem>
                          {Array.from({ length: 31 }, (_, index) => (
                            <MenuItem key={index + 1} value={index + 1}>
                              {index + 1}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.period_length && <FormHelperText>{errors.period_length}</FormHelperText>}
                      </FormControl>
                    </Grid>
                </Grid>    
                </>
              )}
            </Grid>
            {/* Row 2 */}
            <Grid item xs={4} container>
              <Grid item xs={12}>
                <FormControl fullWidth error={!!errors.export_method}>
                  <Typography variant="body2">Choose Delivery Method</Typography>
                  <Select
                    fullWidth
                    value={reportData.export_method}
                    name="export_method"
                    className="form-select"
                    onChange={(e) => {
                      handleInputChange(e);
                      setReportData({ ...reportData, export_method: e.target.value });
                    }}
                  >
                    <MenuItem value="EMAIL">Email</MenuItem>
                    {ftp.length !== 0 && ( <MenuItem value="FTP">FTP</MenuItem> )}
                  </Select>
                  {errors.export_method && <FormHelperText>{errors.export_method}</FormHelperText>}
                </FormControl>
              </Grid>
              {reportData.export_method === 'FTP' && (
                  <>
                  <Grid item xs={12}>
                      <FormControl fullWidth error={!!errors.delevery_method_ftp}>
                      <Typography variant="body2">Choose FTP</Typography>
                      <Select
                        fullWidth
                        className="form-select"
                        value={reportData.delevery_method_ftp || ''}
                        onChange={(e) => setReportData({ ...reportData, delevery_method_ftp: String(e.target.value) })}
                        displayempty="true"
                      >
                        <MenuItem value="" disabled>
                          Select FTP
                        </MenuItem>
                        {ftp.length > 0 ? (
                          ftp.map((ftp) => (
                            <MenuItem key={ftp.id} value={ftp.id}>
                              {ftp.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No ftp Available</MenuItem>
                        )}
                      </Select>
                      {errors.delevery_method_ftp && <FormHelperText>{errors.delevery_method_ftp}</FormHelperText>}
                    </FormControl>
                    </Grid>
                  <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Typography variant="body2"> FTP Folder</Typography>
                        <TextField
                            name="folder"
                            fullWidth
                            className="form-input"
                            value={reportData.folder || ''}
                            onChange={(e) => setReportData({ ...reportData, folder: String(e.target.value) })}
                            displayempty="true"
                          />
                      </FormControl>
                    </Grid>
                  </>
                )}
            </Grid>

            <Grid item xs={4}>
              <Grid item xs={12}>
                <FormControl fullWidth error={!!errors.delivery_frequency}>
                  <Typography variant="body2">Delivery Frequency</Typography>
                  <Select
                    fullWidth
                    value={reportData.delivery_frequency}
                    name="delivery_frequency"
                    className="form-select"
                  onChange={(e) => {
                      handleInputChange(e);
                      setReportData({ ...reportData, delivery_frequency: e.target.value });
                    }}
                    error={!!errors.delivery_frequency}
                  >
                    <MenuItem value="ONCE">Once</MenuItem>
                    <MenuItem value="DAILY_TIME">Daily</MenuItem>
                    <MenuItem value="WEEKLY">Weekly</MenuItem>
                    <MenuItem value="MONTHLY">Monthly</MenuItem>
                  </Select>
                  {errors.delivery_frequency && <FormHelperText>{errors.delivery_frequency}</FormHelperText>}
                </FormControl>
              </Grid>
              {reportData.delivery_frequency === 'DAILY_TIME' && (
                  <>
                  <Grid item xs={12}>
                      <FormControl fullWidth error={!!errors.time_of_day}>
                          <Typography variant="body2">Time of Day</Typography>
                          <CustomFormControl
                              className="custom-form-select"
                              value={reportData.time_of_day}
                              onChange={(e) => setReportData({ ...reportData, time_of_day: String(e.target.value) })}
                              displayEmpty
                              renderValue={(selected) => {
                                  if (!selected) {
                                      return (
                                          <span style={{
                                              fontSize: "12px",
                                              color: "#BAB7B7"
                                          }}>Select a day</span>
                                      );
                                  }
                                  return selected;
                              }}
                              required
                              error={errors.time_of_day}
                              helperText={errors.time_of_day ? "This field is required" : ""}
                          >
                              <MenuItem disabled value="">
                                  <span style={{
                                      fontSize: "12px",
                                      color: "#BAB7B7",
                                      marginLeft: "4px"
                                  }}>Select a day</span>
                              </MenuItem>
                              {Array.from({ length: 31 }, (_, i) => i + 1).map((num) => (
                                  <MenuItem key={num} value={num} sx={{ fontSize: '0.75rem' }}>
                                      {num}
                                  </MenuItem>
                              ))}
                          </CustomFormControl>
                          {errors.time_of_day && <FormHelperText>{errors.time_of_day}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                    <FormControl fullWidth error={!!errors.time_zone}>
                        <Typography variant="body2">Time zone</Typography>
                        <Select
                          className="form-select"
                          value={reportData.time_zone}
                          onChange={(e) => setReportData({ ...reportData, time_zone: String(e.target.value) })}
                          displayEmpty={true}
                          renderValue={(selected) => {
                            if(!selected) {
                              return (
                                <span style={{
                                    fontSize: "12px",
                                    color: "#BAB7B7"
                                }}>Select time Zone</span>
                              );
                            } 
                            const option = timezones.find(
                                (opt) => opt.value === selected
                            );
                            return option ? option.label : selected;

                        }}
                        error={!!errors.time_zone}
                        >
                          <MenuItem value="" disabled>
                            Select Time Zone
                          </MenuItem>
                          {timezones.length > 0 ? (
                            timezones.map((timezone) => (
                              <MenuItem key={timezone.id} value={timezone.id}>
                                {timezone.label}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>No Timezones Available</MenuItem>
                          )}
                        </Select>
                        {errors.time_zone && <FormHelperText>{errors.time_zone}</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </>
                )}
                {reportData.delivery_frequency === 'WEEKLY' && (
                    <Grid item xs={12}>
                      <FormControl fullWidth error={!!errors.day}>
                        <Typography variant="body2">Day of Week</Typography>
                        <CustomFormControl
                          className="custom-form-select"
                          multiple
                          value={reportData.day || []}
                          onChange={(e) => {
                            const { value } = e.target;
                            setReportData({ 
                              ...reportData, 
                              day: value 
                            });
                            setDayOfWeek(value)
                          }}
                          displayEmpty
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                                return (
                                    <span style={{ fontSize: "12px", color: "#BAB7B7" }}>Choose Day</span>
                                );
                            }
                            return selected
                                .map((value) => {
                                    const option = DayOfWeek.find(
                                        (opt) => opt === value
                                    );
                                    return option ? option : value;
                                })
                                .join(", ");
                        }}
                          required
                          error={errors.day}
                          helperText={errors.day ? "This field is required" : ""}
                          size="small"
                          MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                      >
                          <MenuItem disabled value="">
                              <span
                                  style={{
                                      fontSize: "12px",
                                      color: "#BAB7B7",
                                      marginLeft: "4px",
                                  }}
                              >
                                Choose Day
                              </span>
                          </MenuItem>
                          {DayOfWeek.map((option) => (
                              <MenuItem
                                  key={option}
                                  value={option}
                                  sx={{ fontSize: "12px", color: "#373F41" }}
                              >
                                  <Checkbox
                                      checked={reportData?.day?.includes(option)}
                                      sx={{
                                          "& .MuiSvgIcon-root": {
                                              fontSize: 12,
                                          },
                                      }}
                                  />
                                  <ListItemText
                                      primary={option}
                                      primaryTypographyProps={{
                                          style: { fontSize: "12px", fontFamily: "Inter", color: "#373F41" },
                                      }}
                                  />
                              </MenuItem>
                          ))}
                      </CustomFormControl>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 2 }}>
                          {reportData.day && reportData.day.map((value) => {
                              const option = DayOfWeek.find(opt => opt === value);
                              const label = option ? option : value;
                              return (
                                  <Chip
                                      key={value}
                                      label={label}
                                      onDelete={() => handleDelete("day",value)} 
                                      size="small"
                                  />
                              );
                          })}
                      </Box>
                      </FormControl>
                    </Grid>
                  )}
                  {reportData.delivery_frequency === 'MONTHLY' && (
                      <Grid item xs={12}>
                      <FormControl fullWidth error={!!errors.day_month}>
                        <Typography variant="body2">Day of Month</Typography>
                        <Select
                          fullWidth
                          className="form-select"
                          value={reportData.day_month || 1}
                          onChange={(e) => setReportData({ ...reportData, day_month: String(e.target.value) })}
                          displayEmpty={true}
                          renderValue={(selected) => {
                            if (!selected) {
                                return (
                                    <span style={{
                                        fontSize: "12px",
                                        color: "#BAB7B7"
                                    }}>Select a day</span>
                                );
                            }
                            return selected;
                        }}
                        >
                          <MenuItem value="" disabled>
                            Select Day
                          </MenuItem>
                          {Array.from({ length: 31 }, (_, index) => (
                            <MenuItem key={'day-of-month-'+index + 1}  value={index + 1}>
                              {index + 1}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.day_month && <FormHelperText>{errors.day_month}</FormHelperText>}
                      </FormControl>
                    </Grid>
                  )}
              </Grid>

            {/* Row 3 */}
            <Grid item xs={12} sm={12} md={12}>
              <Grid item xs={8}>
                <FormControl fullWidth error={!!errors.recipients}>
                  <Typography variant="body2">Recipients</Typography>
                <Tags
                        whitelist={reportRecipients}
                        placeholder="Recipients"
                        settings={{
                            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        }}
                        value={reportRecipients}
                        defaultValue={reportRecipients.join(',')}
                        onChange={handleRecipientsChange}
                    />
                  {errors.recipients && <FormHelperText>{errors.recipients}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
                  
            {/* Subject */}
            <Grid item xs={12} sm={12} md={12}>
              <Grid  item xs={8}>
                <FormControl fullWidth error={!!errors.subject}>
                    <Typography variant="body2">Subject</Typography>
                    <TextField
                      name="subject"
                      fullWidth
                      placeholder="Subject"
                      className="form-input"
                      value={reportData.subject}
                      onChange={(e) => {
                        handleInputChange(e);
                        setReportData({ ...reportData, subject: e.target.value });
                      }}
                    />
                    {errors.subject && <FormHelperText>{errors.subject}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
                  
            {/* Export File name */}
            <Grid item xs={12} sm={12} md={12}>
              <Grid  item xs={8}>
                <FormControl fullWidth error={!!errors.export_name}>
                  <Typography variant="body2">Export File Name</Typography>
                  <TextField
                    name="export_name"
                    fullWidth
                    placeholder="[TYPE_REPORT]-[ID_REPORT]-[ACCOUNT]-[DATETIME]"
                    className="form-input"
                    value={reportData.export_name}
                    onChange={(e) => {
                      handleInputChange(e);
                      setReportData({ ...reportData, export_name: e.target.value });
                    }}
                  />
                  {errors.export_name && <FormHelperText>{errors.export_name}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>

            {/* Export File Type */}
            <Grid container item spacing={3}  xs={12}>
              <Grid item xs={4}>
                <FormControl fullWidth error={!!errors.export_file_type}>
                  <Typography variant="body2">Export File Type</Typography>
                  <Select
                    fullWidth
                    value={reportData.export_file_type}
                    name="export_file_type"
                    className="form-select"
                    onChange={(e) => {
                      handleInputChange(e);
                      setReportData({ ...reportData, export_file_type: e.target.value });
                    }}
                    error={!!errors.export_file_type}
                  >
                    <MenuItem value="csv">CSV</MenuItem>
                    <MenuItem value="xlsx">Excel</MenuItem>
                  </Select>
                  {errors.export_file_type && (
                    <FormHelperText>{errors.export_file_type}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>  
            </Grid>
        </Grid>
        <Grid xs={12} container item spacing={1} sx={{ marginTop: '10px' }}>
          <Grid  item xs={9}> 
            <Grid container item xs={10}>
                  {showAdditionalSettings && (
                  <>
                  <Grid item xs={5}  sx={{ marginBottom: '10px' }}>
                  <FormControl fullWidth error={!!errors.attached_file}>
                    <Typography variant="body2">Attached File</Typography>
                    <Select
                      fullWidth
                      value={reportData.attached_file}
                      name="attached_file"
                      className="form-select"
                      onChange={(e) => {
                        handleInputChange(e);
                        setReportData({ ...reportData, attached_file: e.target.value });
                      }}
                      error={!!errors.attached_file}
                    >
                      <MenuItem value="ZIP_COMPRESSION">Zip file</MenuItem>
                        <MenuItem value="NO_COMPRESSION">Uncompressed file</MenuItem>
                        <MenuItem value="NO_ATTACHMENT">No attachment</MenuItem>
                    </Select>
                    {errors.attached_file && (
                      <FormHelperText>{errors.attached_file}</FormHelperText>
                    )}
                  </FormControl>
                  </Grid>
                  { whiteLabelEmail.length !== 0 &&  ( 
                      <Grid item xs={5} sx={{ marginLeft: '1rem' }}>
                        <FormControl fullWidth>
                          <Typography variant="body2">WhiteLabel Email</Typography>
                          <Select
                            fullWidth
                            className="form-select"
                            value={reportData.whitelabel_email || 'None Selected'}
                            onChange={(e) => setReportData({ ...reportData, whitelabel_email: String(e.target.value) })}
                            displayempty="true"
                          >
                          <MenuItem value="None Selected" disabled>
                            None Selected
                          </MenuItem>
                          {whiteLabelEmail.length > 0 ? (
                            whiteLabelEmail.map((whiteLabelEmail) => (
                              <MenuItem key={whiteLabelEmail.id} value={whiteLabelEmail.id}>
                                {whiteLabelEmail.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>No Whitelabel Email Available</MenuItem>
                          )}
                        </Select>
                        </FormControl>
                      </Grid>
                    )}
                  </>
                  )}
              <Grid container  spacing={2} item xs={12}  className="report-form-footer-section">
                <Grid  item xs={12}   > 
                    <div className="button-container">
                        <CustomButtonWithIcon className="report-form-button" onClick={handleCreateReport} >
                            {isEdit ? 'Update Report' : 'Create Report'} 
                        </CustomButtonWithIcon>
                        <CustomButtonWithIcon className="report-form-button" onClick={onClose} >
                            Cancel
                        </CustomButtonWithIcon>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
          </Grid>
          <Grid  item xs={3}  sx={ {marginTop: '18px' }}>
            <Button className="additional-settings" onClick={handleToggleSettings}  sx={{ marginRight: 2 }} >
              <DmpIcons.ActionsIcons.CogsGrayIcon className="cogicon" />
              Additional Settings
            </Button>
          </Grid>
        </Grid>
        {errors.unspecified && (
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <FormControl className="error-message" fullWidth error={!!errors.unspecified}>
                {errors.unspecified && <FormHelperText>{errors.unspecified}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
        )}
      </div>
    );
  };

  export default SpFunnelTripAdvisor;
