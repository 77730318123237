export const getSelectedSegments = (platforms, hotelsOrGroups, level) => {
    const selectedPlatform = Array.from(platforms)
    if(selectedPlatform.length > 1){
        return ["CHANNEL"];
    }

    if(selectedPlatform.length === 1) {
        if(level === "HOTEL") {
            if(Array.from(hotelsOrGroups).length && Array.from(hotelsOrGroups).length > 1) {
                return ["HOTELS"];
            }

            if(Array.from(hotelsOrGroups).length && Array.from(hotelsOrGroups).length === 1) {
                if(selectedPlatform?.[0].id === "wego"){
                    return ["HOTELS"]
                }
                return ["MARKET"];
            }
        }

        if(level === "GROUP") {
            if(Array.from(hotelsOrGroups).length && Array.from(hotelsOrGroups).length > 1) {
                return ["GROUPS"];
            }

            if(Array.from(hotelsOrGroups).length && Array.from(hotelsOrGroups).length === 1) {
                return ["HOTELS"];
            }
        }
    }
}