import React, {useState, useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './GroupsHotelsList.scss';
import HotelsTable from '../Utils/Table/HotelsTable';
import ActionMenu from './ActionMenu';
import UngroupSelectionModal from '../Utils/Modal/ActionModal/UngroupSelectionModal';
import {exportToCsv} from '../Utils/CsvExport';
import {Box, CircularProgress} from '@mui/material';
import Modal from '../Utils/Modal/ActionModal/Modal';
import DeleteConfirmationModal from '../Utils/Modal/ActionModal/DeleteConfirmationModal';
import UploadCSVModal from "../Utils/Modal/UploadCSVModal/UploadCSVModal";
import SearchTableInput from "../../../components/SearchTableInput/SearchTableInput";
import CustomButtonWithIcon from "../../../components/CustomButtonWithIcon/Buttons";

const GroupsHotelsList = ({
                              selectedGroup,
                              groupHotels,
                              handleUngroupSelection,
                              updateGroupName,
                              loading,
                              error,
                              onConfirmDelete,
                              deleteError,
                              updateGroupError,
                              onUploadCsv,
                              csvError,
                              csvLoading
                          }) => {
    const [filteredHotels, setFilteredHotels] = useState(groupHotels);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedHotels, setSelectedHotels] = useState([]);
    const [showModal, setShowModal] = useState(false); // HotelTable for editing group name
    const [showUngroupModal, setShowUngroupModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUploadCsvModal, setShowUploadCsvModal] = useState(false);
    const [groupName, setGroupName] = useState(selectedGroup?.name);
    const [searchTerm, setSearchTerm] = useState('');

    const filteredGroupHotels = groupHotels.filter(group =>
        group.name && group.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        setFilteredHotels(groupHotels);
        setGroupName(selectedGroup?.name);
        setAnchorEl(null);
        setSelectedHotels([]);
    }, [groupHotels, selectedGroup]);

    const handleGroupSettings = () => {
        setAnchorEl(null);
        setShowModal(true);
    };

    const handleCheckboxChange = (hotelId) => {
        setSelectedHotels((prevSelected) =>
            prevSelected.includes(hotelId)
                ? prevSelected.filter(id => id !== hotelId)
                : [...prevSelected, hotelId]
        );
    };

    const handleExportCsv = () => {
        const csvData = filteredHotels.map(hotel => [
            hotel.id,
            hotel.name,
            hotel.country,
            hotel.groupName || 'Ungrouped',
        ]);
        exportToCsv(`${selectedGroup?.name || 'group'}_hotels.csv`, [
            ['Hotel ID', 'Hotel Name', 'Country', 'Group Name'],
            ...csvData,
        ]);

        setAnchorEl(null);
    };

    const handleUngroupSelectionAction = () => setShowUngroupModal(true);

    const handleUngroupConfirm = async () => {
        await handleUngroupSelection(selectedHotels);
        setShowUngroupModal(false); // Explicitly close modal after success
    };

    const handleDeleteGroup = () => {
        setAnchorEl(null);
        setShowDeleteModal(true);  // Open delete modal
    };

    const onUpdate = async (e) => {
        e.preventDefault();
        const success = await updateGroupName(selectedGroup.id, groupName);
        if (success) {
            setShowModal(false);
        }
    };


    return (
        <div className="groups-hotels-list">
            <Box className="groupTableHeaderTop">
                <h3>Currently Editing: {selectedGroup?.name || 'No Group Selected'} {`${selectedGroup?.name ? `(${filteredGroupHotels?.length})`: ""}`}</h3>
                <span style={{display: 'flex',alignItems: 'center'}}>
                    <SearchTableInput
                        placeholder={"Search"}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {selectedGroup && (
                        <IconButton
                            aria-label="more"
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                        >
                            <MoreVertIcon/>
                        </IconButton>
                    )}
                 </span>
            </Box>

            <ActionMenu
                anchorEl={anchorEl}
                handleCloseMenu={() => setAnchorEl(null)}
                handleGroupSettings={handleGroupSettings}
                handleExportCsv={handleExportCsv}
                handleDeleteGroup={handleDeleteGroup}
                handleUploadCsv={() => {
                    setShowUploadCsvModal(true);
                    setAnchorEl(null);
                }}
            />

            {/* Hotels table */}
            <div className="hotels-table-wrapper">
                <HotelsTable
                    filteredHotels={filteredGroupHotels}
                    selectedHotels={selectedHotels}
                    handleCheckboxChange={handleCheckboxChange}
                    showGroupName={false}
                    isThirdComponent={false}
                    handleUngroupSelectionAction={handleUngroupSelectionAction}
                    isRemoveButton
                />
            </div>

            {/* Ungroup HotelTable */}
            <UngroupSelectionModal
                open={showUngroupModal}
                onClose={() => setShowUngroupModal(false)}
                onConfirm={handleUngroupConfirm} // Close modal after confirming
                loading={loading}
                error={error}
            />

            <DeleteConfirmationModal
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)} // Close modal
                onConfirm={async () => {
                    const success = await onConfirmDelete(); // Call delete from parent
                    if (success) setShowDeleteModal(false); // Close on success
                }}
                groupName={selectedGroup?.name}
                loading={loading}
                error={deleteError} // Pass delete error
            />

            <UploadCSVModal
                open={showUploadCsvModal}
                onClose={() => setShowUploadCsvModal(false)}
                onUpload={onUploadCsv}
                loading={csvLoading}
                error={csvError}
            />

            {/* Edit Group Name HotelTable */}
            {showModal && (
                <Modal onClose={() => setShowModal(false)}>
                    <form className="group-form">
                        <h3>Edit Group Name</h3>
                        <input
                            placeholder="Group Name"
                            autoComplete="off"
                            type="text"
                            id="groupName"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            required
                            style={{
                                border: updateGroupError ? '1px solid #D32F2F' : '',
                                outline: 'none',
                            }}
                        />

                        {/* Display the error if there is one */}
                        {updateGroupError && <span style={{
                            marginTop: '-8px',
                            color: '#D32F2F',
                            fontSize: "12px",
                            fontFamily: "inter"
                        }}>{updateGroupError}</span>}

                        <div className="modal-actions">
                            {/* Show loader while waiting for the update */}
                            {loading ? (
                                <CircularProgress/>
                            ) : (
                                <>
                                    <div
                                        className="button-container"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            gap: '16px', // Adjust gap size as needed
                                        }}
                                    >
                                        <CustomButtonWithIcon
                                            color="#b0acac"
                                            hoverColor="#12794F"
                                            titleColor="#373F41"
                                            hoverTitleColor={"white"}
                                            width={"85px"}
                                            height={"24px"}
                                            borderRadius="20px"
                                            textStyle={{
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                lineHeight: '14.52px',
                                                textAlign: 'center',
                                            }}
                                            onClick={() => setShowModal(false)}
                                        >
                                            Cancel
                                        </CustomButtonWithIcon>
                                        <CustomButtonWithIcon
                                            color="#b0acac"
                                            hoverColor="#12794F"
                                            titleColor="#373F41"
                                            hoverTitleColor={"white"}
                                            width={"85px"}
                                            height={"24px"}
                                            borderRadius="20px"
                                            textStyle={{
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                lineHeight: '14.52px',
                                                textAlign: 'center',
                                            }}
                                            onClick={onUpdate}
                                        >
                                            Update
                                        </CustomButtonWithIcon>
                                    </div>


                                </>
                            )}
                        </div>
                    </form>
                </Modal>
            )}
        </div>
    );
};

export default GroupsHotelsList;
