import React from "react";
import {Box, Grid, Select} from "@mui/material";
import {TopHotelsAvailableMetrics} from "../../Utils/AvailableMetrics";
import { COLORS } from "../../Utils/Colors";
import MenuItem from "@mui/material/MenuItem";
import {TopHotelsOptions} from "../../Utils/Options";

function TopHotelsTable({ selectedHotels, tableMetrics, formatValue, handleTableMetricChange, selectedOption, handleDropdownChange, currencySymbol}) {
    return (
        <Grid item xs={8} sx={{ paddingLeft: '20px !important'}}>
            <Grid item xs={12}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                }}>
                    <Select
                        value={selectedOption}
                        onChange={handleDropdownChange}
                        variant="outlined"
                        displayEmpty
                        sx={{
                            color: "black",
                            fontSize: "12px",
                            padding: "0",
                            border: "none",
                            height: "28px",
                            fontWeight: 600,
                            background: "transparent",
                            display: "flex",
                            fontFamily: "Inter",
                            "& .MuiSelect-icon": {
                                color: "#BAB7B7",
                            },
                            "& .MuiSelect-select": {
                                padding: 0
                            },
                            "& fieldset": {
                                border: "none",
                                padding: 0
                            }
                        }}
                    >
                        {TopHotelsOptions.map((option) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                sx={{
                                    fontSize: "12px",
                                    padding: "4px 8px",
                                    lineHeight: "1.2",
                                    fontFamily: "Inter"
                                }}
                            >
                                {option.key}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Grid>
            <Grid item xs={12} className="top-hotels-table">
                <table>
                    <thead>
                    <tr>
                        <th className="color-th"> </th>
                        <th> </th>
                        {tableMetrics.map((metric, index) => (
                            <th
                                key={index}
                                className={index === tableMetrics.length - 1 && window.innerWidth < 1440 ? "hide-column" : ""}
                            >
                                <Select
                                    value={metric}
                                    onChange={(event) => handleTableMetricChange(event, index)}
                                    sx={{
                                        color: "black",
                                        fontSize: "12px",
                                        fontFamily: "Inter",
                                        padding: "0",
                                        border: "none",
                                        height: "28px",
                                        fontWeight: 600,
                                        minWidth: "auto",
                                        background: "transparent",
                                        "& .MuiSelect-icon": {
                                            color: "#BAB7B7",
                                        },
                                        "& .MuiSelect-select": {
                                            paddingBottom: "0",
                                            paddingTop: "0",
                                        },
                                        "& fieldset": {
                                            border: "none",
                                            padding: 0,
                                        },
                                    }}
                                >
                                    {Object.entries(TopHotelsAvailableMetrics).map(([metricKey, label]) => (
                                        <MenuItem
                                            key={metricKey}
                                            value={metricKey}
                                            disabled={tableMetrics.includes(metricKey) && metricKey !== metric}
                                            sx={{
                                                fontSize: "12px",
                                                padding: "4px 8px",
                                                lineHeight: "1.2",
                                                fontFamily: "Inter",
                                            }}
                                        >
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {selectedHotels.length > 0 ? (
                        selectedHotels.map((hotel, index) => (
                            <tr key={index}>
                                <td className="color-td">
                                    <span
                                        className="hotel-color"
                                        style={{ backgroundColor: COLORS[index % COLORS.length] }}
                                    ></span>
                                </td>
                                <td className="first-td">
                                    {hotel.hotel_name}
                                </td>
                                {tableMetrics.map((metric, i) => (
                                    <td key={i} align="left">
                                        <span>{formatValue(metric, hotel, currencySymbol)}</span>
                                    </td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td align="center" className="color-td">
                                {" "}
                            </td>
                            <td colSpan={tableMetrics.length + 1} align="left" className="no-data">
                                No data available
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </Grid>
        </Grid>
    );
}

export default TopHotelsTable;