import React, { useMemo, useState } from "react";
import "./DeviceComponent.scss";
import { Box, Grid } from "@mui/material";
import TableDevice from "./TableDevice";
import PieChartDevice from "./PieChartDevice";
import { COLORS } from "../Utils/Colors";
import CustomDrawerComponent from "../Utils/CustomDrawerComponent";
import { formatValue } from "../Utils/FormatMetricValue";

const DeviceComponent = ({ selectedOption, handleDropdownChange, deviceMetrics, errorMessage = null }) => {
    const [selectedMetric, setSelectedMetric] = useState("clicks");
    const [tableMetrics, setTableMetrics] = useState({
        metric1: "clicks_kpi",
        metric2: "conversion_kpi",
        metric3: "roas_kpi",
    });

    const handleMetricChange = (event) => {
        setSelectedMetric(event.target.value);
    };

    const handleTableMetricChange = (event, metricKey) => {
        setTableMetrics({ ...tableMetrics, [metricKey]: event.target.value });
    };

    const totalMetricValue = useMemo(() => {
        if (!deviceMetrics || Object.keys(deviceMetrics).length === 0) return 0;

        return Object.entries(deviceMetrics)
            .filter(([key]) => key.startsWith("show_") && deviceMetrics[key])
            .reduce((total, [key]) => {
                const actualKey = key.replace("show_", "");
                const metricValue = Number(deviceMetrics[actualKey]?.[`${selectedMetric}_kpi`] || 0);
                return total + metricValue;
            }, 0);
    }, [deviceMetrics, selectedMetric]);

    const chartData = useMemo(() => {
        if (!deviceMetrics || Object.keys(deviceMetrics).length === 0) return [];

        return Object.entries(deviceMetrics)
            .filter(([key]) => key.startsWith("show_") && deviceMetrics[key])
            .map(([key], index) => {
                const actualKey = key.replace("show_", "");
                const metricValue = Number(deviceMetrics[actualKey]?.[`${selectedMetric}_kpi`] || 0);
                const percentage = totalMetricValue > 0 ? ((metricValue / totalMetricValue) * 100).toFixed(1) : 0;

                return {
                    name: deviceMetrics[actualKey]?.device_name || "Unknown",
                    value: metricValue,
                    percentage,
                    color: COLORS[index % COLORS.length],
                };
            });
    }, [deviceMetrics, selectedMetric, totalMetricValue]);

    const tableData = useMemo(() => {
        if (!deviceMetrics || Object.keys(deviceMetrics).length === 0) return [];

        return Object.entries(deviceMetrics)
            .filter(([key]) => key.startsWith("show_") && deviceMetrics[key])
            .map(([key]) => {
                const actualKey = key.replace("show_", "");
                const device = deviceMetrics[actualKey] || {};

                return {
                    name: device.device_name || "Unknown",
                    metric1: formatValue(tableMetrics.metric1, device, deviceMetrics?.currencySymbol),
                    metric2: formatValue(tableMetrics.metric2, device, deviceMetrics?.currencySymbol),
                    metric3: formatValue(tableMetrics.metric3, device, deviceMetrics?.currencySymbol),
                    color: COLORS[Object.keys(deviceMetrics).indexOf(key) % COLORS.length],
                };
            });
    }, [deviceMetrics, tableMetrics]);

    const isLoading = Object.keys(deviceMetrics).length === 0;

    return (
        <Box
            className="device-reporting-component"
            position="relative"
            style={{ overflow: "hidden" }}
        >
            <CustomDrawerComponent isLoading={isLoading} errorMessage={errorMessage} />
            <Grid container spacing={2} className="device-metrics-container">
                <TableDevice
                    selectedOption={selectedOption}
                    handleDropdownChange={handleDropdownChange}
                    tableMetrics={tableMetrics}
                    handleTableMetricChange={handleTableMetricChange}
                    tableData={tableData}
                />

                {!(Object.keys(deviceMetrics).length === 0) && (
                    <PieChartDevice
                        selectedMetric={selectedMetric}
                        handleMetricChange={handleMetricChange}
                        chartData={chartData}
                        currencySymbol={deviceMetrics.currency_symbol}
                    />
                )}
            </Grid>
        </Box>
    );
};

export default DeviceComponent;
