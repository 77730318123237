import {
  APP_BING_BIDDING_API_ENDPOINT,
  APP_GHA_BIDDING_API_ENDPOINT,
  APP_GLOBAL_REPORTING_API_ENDPOINT,
  APP_KAYAK_BIDDING_API_ENDPOINT,
  APP_SKYSCANNER_BIDDING_API_ENDPOINT,
  APP_TRIPADVISOR_BIDDING_API_ENDPOINT,
  APP_TRIVAGO_BIDDING_API_ENDPOINT,
  APP_WEGO_BIDDING_API_ENDPOINT,
} from "constants/api_urls";
import "./chartContainer.scss";
import GoogleAds from "assets/images/GoogleHotelAds.svg";
import TripAdvisor from "assets/images/TripAdvisor.svg";
import Trivago from "assets/images/Trivago.svg";
import SkyScanner from "assets/images/SkyScanner.svg";
import Kayak from "assets/images/Kayak.svg";
import BingAds from "assets/images/BingAds.svg";
import Wego from "assets/images/wego.svg";
import TripadvisorSp from "assets/images/TripadvisorSp.svg";
import TrivagoSp from "assets/images/TrivagoSp.svg";
import GoogleHotelsFbl from "assets/images/GoogleHotelsFbl.svg";
import GoogleHotelsPpa from "assets/images/GoogleHotelsPpa.svg";
import { Link } from "react-router-dom";

export const api_urls_charts = {
  skyScanner: APP_SKYSCANNER_BIDDING_API_ENDPOINT,
  tripadvisor: APP_TRIPADVISOR_BIDDING_API_ENDPOINT,
  googlehotelads: APP_GHA_BIDDING_API_ENDPOINT,
  bing: APP_BING_BIDDING_API_ENDPOINT,
  trivago: APP_TRIVAGO_BIDDING_API_ENDPOINT,
  kayak: APP_KAYAK_BIDDING_API_ENDPOINT,
  wego: APP_WEGO_BIDDING_API_ENDPOINT,
  googleHotelAdsPropertyPromotionAds: APP_GHA_BIDDING_API_ENDPOINT,
  googleHotelAdsFBL: APP_GHA_BIDDING_API_ENDPOINT,
  tripadvisorSponsoredPlacement: APP_TRIPADVISOR_BIDDING_API_ENDPOINT,
  trivagoSponsoredListing: APP_TRIVAGO_BIDDING_API_ENDPOINT,
  global: APP_GLOBAL_REPORTING_API_ENDPOINT,
};

export const channelIds = {
  googlehotelads: "GHA_META",
  googleHotelAdsPropertyPromotionAds: "GHA_PPA",
  googleHotelAdsFBL: "GHA_FBL",
  skyScanner: "SKYSCANNER",
  wego: "WEGO",
  trivago: "TRIVAGO_META",
  tripadvisor: "TRIPADVISOR_META",
  tripadvisorSponsoredPlacement: "TRIPADVISOR_SP",
  trivagoSponsoredListing: "TRIVAGO_SL",
  bing: "BING",
  kayak: "KAYAK",
};

export const ChannelImageMapper = (to) => ({
  GHA_META: <Link to={to}><img src={GoogleAds} alt={"GoogleAdsIcon"}/></Link>,
  GHA_PPA: <Link to={to}><img src={GoogleHotelsPpa} alt={"GoogleHotelPPAIcon"}/></Link>,
  GHA_FBL: <Link to={to}><img src={GoogleHotelsFbl} alt={"GoogleHotelFBLIcon"}/></Link>,
  TRIPADVISOR_META: <Link to={to}><img src={TripAdvisor} alt={"TripadvisorIcon"}/></Link>,
  TRIPADVISOR_SP: <Link to={to}><img src={TripadvisorSp} alt={"TripadvisorSPIcon"}/></Link>,
  TRIVAGO_SL: <Link to={to}><img src={TrivagoSp} alt={"TrivagoSPIcon"}/></Link>,
  TRIVAGO_META: <Link to={to}><img src={Trivago} alt={"TrivagoIcon"}/></Link>,
  SKYSCANNER: <Link to={to}><img src={SkyScanner} alt={"SkyscannerIcon"}/></Link>,
  KAYAK: <Link to={to}><img src={Kayak} alt={"KayakIcon"}/></Link>,
  BING: <Link to={to}><img src={BingAds} alt={"BingAdsIcon"}/></Link>,
  WEGO: <Link to={to}><img src={Wego} alt={"WegoIcon"}/></Link>,
});

export const getChartReportURLByPlatfrom = (platform) => {
  if (!platform.length) {
    return `${api_urls_charts["global"]}/api/global/chart-reporting/`;
  }

  if (platform.length === 1) {
    switch (platform?.[0]?.id) {
      case "skyScanner":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/skyscanner/chart-reporting/`;
      case "wego":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/wego/chart-reporting/`;
      case "trivago":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/trivago-meta/chart-reporting/`;
      case "tripadvisor":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/tripadvisor-meta/chart-reporting/`;
      case "googlehotelads":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/google-hotel-ads-meta/chart-reporting/`;
      case "googleHotelAdsPropertyPromotionAds":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/google-hotel-ads-ppa/chart-reporting/`;
      case "googleHotelAdsFBL":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/google-hotel-ads-fbl/chart-reporting/`;
      case "tripadvisorSponsoredPlacement":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/tripadvisor-sp/chart-reporting/`;
      case "trivagoSponsoredListing":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/trivago-sl/chart-reporting/`;
      case "bing":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/bing-hotel-ads/chart-reporting/`;
      case "kayak":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/kayak/chart-reporting/`;
      default:
        return null;
    }
  }

  if (platform.length > 1) {
    if (platform.every((pl) => pl.label.toLowerCase().includes("google"))) {
      return `${api_urls_charts["googlehotelads"]}/api/google-hotel-ads-all/chart-reporting/`;
    } else if (
      platform.every((pl) => pl.label.toLowerCase().includes("trivago"))
    ) {
      return `${api_urls_charts["trivago"]}/api/trivago-all/chart-reporting/`;
    } else if (
      platform.every((pl) => pl.label.toLowerCase().includes("tripadvisor"))
    ) {
      return `${api_urls_charts["tripadvisor"]}/api/tripadvisor-all/chart-reporting/`;
    } else {
      return `${api_urls_charts["global"]}/api/global/chart-reporting/`;
    }
  }
};

export const getTableReportURLByPlatfrom = (platform) => {
  if (!platform.length) {
    return `${api_urls_charts["global"]}/api/global/reporting/`;
  }

  if (platform.length === 1) {
    switch (platform?.[0]?.id) {
      case "skyScanner":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/skyscanner/reporting/`;
      case "wego":
        return `${api_urls_charts[platform?.[0]?.id]}/api/wego/reporting/`;
      case "trivago":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/trivago-meta/reporting/`;
      case "tripadvisor":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/tripadvisor-meta/reporting/`;
      case "googlehotelads":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/google-hotel-ads-meta/reporting/`;
      case "googleHotelAdsPropertyPromotionAds":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/google-hotel-ads-ppa/reporting/`;
      case "googleHotelAdsFBL":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/google-hotel-ads-fbl/reporting/`;
      case "tripadvisorSponsoredPlacement":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/tripadvisor-sp/reporting/`;
      case "trivagoSponsoredListing":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/trivago-sl/reporting/`;
      case "bing":
        return `${
          api_urls_charts[platform?.[0]?.id]
        }/api/bing-hotel-ads/reporting/`;
      case "kayak":
        return `${api_urls_charts[platform?.[0]?.id]}/api/kayak/reporting/`;
      default:
        return null;
    }
  }

  if (platform.length > 1) {
    if (platform.every((pl) => pl.label.toLowerCase().includes("google"))) {
      return `${api_urls_charts["googlehotelads"]}/api/google-hotel-ads-all/reporting/`;
    } else if (
      platform.every((pl) => pl.label.toLowerCase().includes("trivago"))
    ) {
      return `${api_urls_charts["trivago"]}/api/trivago-all/reporting/`;
    } else if (
      platform.every((pl) => pl.label.toLowerCase().includes("tripadvisor"))
    ) {
      return `${api_urls_charts["tripadvisor"]}/api/tripadvisor-all/reporting/`;
    } else {
      return `${api_urls_charts["global"]}/api/global/reporting/`;
    }
  }
};
