import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  account: null,
  level: null,
  hotel_id: [],
  group_id: [],
  hotelList: [],
  groupList: [],
  can_use_groups: [],
  accounts : []
};
export const accountSlice = createSlice({
  name: "accountSlice",
  initialState: initialState,
  reducers: {
    updateAccount: (state, action) => {
      state.account = action.payload;
    },
    updateLevel: (state, action) => {
      state.level = action.payload;
    },
    updateHotelId: (state, action) => {
      state.hotel_id = action.payload;
    },
    updateGroupId: (state, action) => {
      state.group_id = action.payload;
    },
    updateHotelList: (state,action) => {
      state.hotelList = action.payload;
    },
    updateGroupList: (state,action) => {
      state.groupList = action.payload;
    },
    updateCanUseGroups: (state,action) => {
      state.can_use_groups = action.payload;
    },
    updateAccountList: (state,action) => {
      state.accounts = action.payload;
    },
    resetAccount: (state) => initialState
  },
});

export const { updateAccountList, updateAccount, updateGroupId,updateHotelId, updateLevel, resetAccount, updateHotelList, updateGroupList, updateCanUseGroups } = accountSlice.actions;
export default accountSlice.reducer;
