import React, { useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { IconButton, Box, CircularProgress, Select, MenuItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const AccountsTable = ({
    accountsData,
    availableAccounts,
    newAccount,
    onAccountSelection,
    onEditAccount,
    onDeleteAccount,
    handleAccountClick,
    onCancelNewAccount,
    selectedAccount,
    hotelsData,
    allAccountsForCurrentUser,
    loading
}) => {
    const [selectedNewAccount, setSelectedNewAccount] = useState(null);
    const getHotelCountForAccount = (accountId) => {
        return hotelsData.filter(hotel => hotel.idaccount === accountId).length;
    };

    const accountColumns = [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 80,
            minWidth: 50,
        },
        {
            accessorKey: 'name',
            header: 'Name',
            size: 100,
            Cell: ({ row }) => {
                if (newAccount && row.original.id === null) {
                    return (
                        <Select
                            value={selectedNewAccount ? selectedNewAccount.id : ''}
                            onChange={(e) => {
                                const selectedAccount = availableAccounts.find(acc => acc.id === e.target.value);
                                setSelectedNewAccount(selectedAccount);
                            }}
                            sx={{
                                fontFamily: 'Inter',
                                fontSize: '11px',
                                minWidth: '100px',
                                width: '100%',
                                height: '24px',
                                padding: '0px',
                                margin: '0px',
                                boxSizing: 'border-box',
                                '& fieldset': {
                                    border: 'none',
                                },
                                '& .MuiSelect-select': {
                                    padding: '0',
                                },
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        fontFamily: 'Inter',
                                        fontSize: '11px',
                                        maxHeight: 200,
                                        width: '80px'
                                    }
                                }
                            }}
                            displayEmpty
                            fullWidth
                        >
                            <MenuItem sx={{ fontSize: '12px', padding: '2px 8px' }} value="" disabled>Select an account</MenuItem>
                            {availableAccounts.map(account => (
                                <MenuItem sx={{ fontSize: '12px', padding: '2px 8px' }} key={account.id} value={account.id}>
                                    {account.name}
                                </MenuItem>
                            ))}
                        </Select>
                    );
                }
                return row.original.name;
            }
        },
        {
            accessorKey: 'readwriteOption',
            header: 'Permissions',
            size: 120,
            minWidth: 120,
            Cell: ({ row }) => {
                const readwriteOptionForMe = allAccountsForCurrentUser.find(item => item.id === row.original.id)?.readwriteOption;
                const isNewAccount = newAccount && row.original.id === null;
                return (
                    <Select
                        value={isNewAccount ? 0 : (row.original.readwriteOption === 'WRITE' ? 1 : 0)}
                        onChange={(e) => !isNewAccount && onEditAccount({
                            ...row.original,
                            readwrite_option: e.target.value,
                            id_account: row.original.id,
                            all_hotels: row.original.allHotels === "ALL" ? 1 : 0
                        })}
                        fullWidth
                        disabled={isNewAccount}
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: '11px',
                            minWidth: '100px',
                            width: 'auto',
                            height: '24px',
                            border: 'none',
                            boxShadow: 'none',
                            '& fieldset': {
                                border: 'none',
                            },
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    maxHeight: 200,
                                    width: '80px',
                                    fontFamily: 'Inter',
                                    fontSize: '11px',
                                }
                            }
                        }}
                    >
                        <MenuItem sx={{ fontSize: '12px', padding: '2px 8px' }} value={0}>
                            Read Only
                        </MenuItem>
                        {
                            readwriteOptionForMe === 'WRITE' && (
                                <MenuItem sx={{ fontSize: '12px', padding: '2px 8px' }} value={1}>
                                    Write
                                </MenuItem>
                            )
                        }
                    </Select>
                );
            },
        },
        {
            accessorKey: 'allHotels',
            header: 'Hotels',
            size: 100,
            Cell: ({ row }) => {
                const isNewAccount = newAccount && row.original.id === null;
                if (isNewAccount) {
                    return '-';
                }

                const hotelCount = getHotelCountForAccount(row.original.id);
                return row.original.allHotels === 'ALL' ? 'All Hotels' : `${hotelCount} Hotels`;
            }
        },
        {
            header: 'Actions',
            size: 80,
            enableColumnActions: false,
            Cell: ({ row }) => {
                const isNewAccount = newAccount && row.original.id === null;
                return (
                    <Box>
                        {isNewAccount ? (
                            <>
                                <IconButton
                                    onClick={() => {
                                        if (selectedNewAccount) {
                                            onAccountSelection(selectedNewAccount);
                                        }
                                    }}
                                    disabled={!selectedNewAccount}
                                    sx={{
                                        color: !selectedNewAccount ? 'gray' : 'primary.main',
                                    }}
                                >
                                    <CheckIcon color="primary" fontSize="small" />
                                </IconButton>
                                <IconButton onClick={onCancelNewAccount}>
                                    <CancelIcon color="error" fontSize="small" />
                                </IconButton>
                            </>
                        ) : (
                            <>
                                <IconButton onClick={() => handleAccountClick(row.original)}>
                                    <EditIcon color="action" fontSize="small" />
                                </IconButton>
                                <IconButton onClick={() => onDeleteAccount(row.original)}>
                                    <DeleteIcon color="error" fontSize="small" />
                                </IconButton>
                            </>
                        )}
                    </Box>
                );
            },
        },
    ];

    return (
        <Box sx={{ position: 'relative', overflowX: 'auto', maxWidth: '100%' }}>  {/* Responsive scrollable container */}
            {loading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                        backgroundColor: 'rgba(255,255,255,0.7)',
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <MaterialReactTable
                columns={accountColumns}
                data={newAccount ? [newAccount, ...accountsData] : accountsData}
                enableSorting={true}
                enableColumnFilters={true}
                enableColumnVisibility={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableToolbarInternalActions={false}
                enableColumnResizing={false}
                enableRowActions={false}
                enableRowSelection={false}
                enableGlobalFilter={false}
                enableHiding={false}
                muiTableBodyCellProps={({ row }) => ({
                    sx: {
                        height: '40px',
                        padding: '0 16px',
                        fontFamily: 'Inter',
                        fontSize: '10px',
                        fontWeight: 400,
                        lineHeight: '12.1px',
                        textAlign: 'left',
                        backgroundColor: selectedAccount !== null && row.original.id === selectedAccount.id ? '#e0e0e0' : 'inherit',
                    },
                })}
                muiTableHeadCellProps={{
                    sx: {
                        padding: '0 16px',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontWeight: 600,
                        lineHeight: '13.31px',
                        textAlign: 'left',
                    },
                }}
                muiTopToolbarProps={{
                    sx: {
                        display: 'none',
                    },
                }}
                muiBottomToolbarProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        width: 'auto',
                        maxWidth: '750px',
                        overflowX: 'hidden',
                    },
                }}
                enablePagination={false}
            />
        </Box>
    );
};

export default AccountsTable;
