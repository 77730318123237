import { Grid, Tooltip } from "@mui/material";
import Table from "components/Table";
import {useEffect, useRef, useState} from "react";
import { createSearchParams } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import "./TableContainer.scss";
import { APP_GOOGLE_SEM_API_ENDPOINT } from "constants/api_urls";
import EditIcon from "../../../assets/images/ActionIcons/Edit.svg";
import {
  updateHotelId,
} from "store/slices/account/accountSlice";
import {getUserToken} from "../../../utils/functions/userToken";

const TableContainer = ({
  hotelList = [],
  isChartCollapsed,
  setIsChartCollapsed,
  onEditClick,
  onEditCampaign,
  isAccountWritable,
}) => {
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isPaginationEnabled, setIsPaginationEnabled] = useState(false);
  const [paginationObject, setPaginationObject] = useState({});
  const [pageSize, setPageSize] = useState(15);
  const [pageIndex, setPageIndex] = useState(0);
  const [sortingState, setSortingState] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch()

  const token = getUserToken() || "";
  const { account_id = "" } = useSelector(
    (state) => state?.account?.account || {}
  );
  const {hotel_id = [], level } = useSelector(state =>state?.account)
  const { currentDateRange = {},comparitionDateRange={}, compareWith } = useSelector((state) => state?.calender);
  const {
    segment_list = [],
    campaign = [],
  } = useSelector((state) => state?.searchAds);
  const prevSegmentList = useRef(segment_list);

  useEffect(() => {
    const hasSegmentsChanged = JSON.stringify(prevSegmentList.current) !== JSON.stringify(segment_list);
    if (hasSegmentsChanged && pageIndex !== 0) {
      prevSegmentList.current = segment_list;
      setPageIndex(0);
      return;
    }

    if (hasSegmentsChanged) {
      prevSegmentList.current = segment_list;
    }
    const params = {
      account_id,
      level,
      "campaign_id[]": campaign?.map((c) => c.id),
      "segment_list[]": segment_list,
      start_date: format(
        currentDateRange?.startDate || new Date(),
        "yyyy-MM-dd"
      ),
      end_date: format(currentDateRange?.endDate || new Date(), "yyyy-MM-dd"),
      ...(comparitionDateRange?.startDate && comparitionDateRange?.endDate
        ? {
            compare_start_date: format(
              comparitionDateRange?.startDate,
              "yyyy-MM-dd"
            ),
            compare_end_date: format(
              comparitionDateRange?.endDate,
              "yyyy-MM-dd"
            ),
          }
        : {}),
      "hotel_id[]": hotel_id,
      page_number: pageIndex,
      number_of_rows: pageSize,
      "sort_asc[]": sortingState
        ?.filter((sort) => !sort?.desc)
        ?.map((s) => s?.id),
      "sort_desc[]": sortingState
        ?.filter((sort) => sort?.desc)
        ?.map((s) => s?.id),
    };

    if (account_id && level && hotelList?.every(h => String(h?.account_id) === String(account_id))) {
      setLoading(true);
      axios
        .post(
          `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/campaigns/reporting/`, createSearchParams(
            params
          ),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const data = res?.data;
          setIsPaginationEnabled(data?.paginator_enabled);
          setPaginationObject(data);
          const sortedColumns = [
            ...(data?.columns?.filter(
              (col) => col?.type !== "METRICS" && col?.type === "SEGMENT"
            ) || []),
            ...(data?.columns?.filter(
              (col) => col?.type !== "METRICS" && col?.type !== "SEGMENT"
            ) || []),
            ...(data?.columns?.filter((col) => col?.type === "METRICS") || []),
          ];
          const mappedColumns = sortedColumns?.map((col, index) => {
            let renderCell = ({ row, cell }) => {
              let style = {};
              if (index === 0) {
                style.marginLeft = '10px';
              }
              if (row.id === "0" && cell.column.id === "0") {
                style = {
                  ...style,
                  fontFamily: "Inter, sans-serif",
                  fontSize: "12px",
                  fontWeight: "600",
                };
              }
              return (
                  compareWith && col?.type !== "SEGMENT" ? <Tooltip
                      title={
                        col.type === "BUDGET_SPENT"
                            ? row?.original?.budget_remaining
                            : `${row?.original?.[
                                `${cell.column?.columnDef?.accessorKey}_compare`
                                ]} ${row?.original?.[`${cell.column?.columnDef?.accessorKey}_compare_percent`]}%`
                      }
                  >
                    <span style={style}>{cell.getValue()}</span>
                  </Tooltip>: <span style={style}>{cell.getValue()}</span>
              );
            };

            if(col?.id === "idhotel"){
              renderCell = ({ row, cell }) => {
                return (
                  row?.id !== "0" ? <span
                  className="clickable-element"
                    style={{
                      cursor: "pointer",
                      marginLeft: index === 0 ? "10px" : "0",
                    }}
                    onClick={() => {
                      const selectedHotel = hotelList.find(
                        (h) => h.hotel_id === Number(row?.original?.idhotel_id)
                      )?.hotel_id;
                      dispatch(updateHotelId([selectedHotel]));
                    }}
                  >
                    {cell.getValue()}
                  </span> : <span style={{
                      marginLeft: index === 0 ? "10px" : "0",
                  }}>{cell.getValue()}</span>
                );
              };
            }

            if (col.id === "idcampaign") {
              renderCell = ({ cell, row }) => {
                const isFirstRow = row.index === 0;
                return (
                    <span key={index} className="campaign-cell">
                     <span>{cell.getValue()}</span>{" "}
                      {!isFirstRow && isAccountWritable && (
                          <span
                              className={"edit-icon-table-row"}
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                  onEditCampaign(row.original.idcampaign_id)
                              }
                          >
                         <img src={EditIcon} alt="Edit" />
                           </span>
                      )}
                     </span>
                );
              };
            }
            return {
              id: index.toString(),
              accessorKey: col.id,
              Header: <Tooltip title={col?.description}><span>{col?.label}</span></Tooltip>,
              header: col?.label,
              Cell: renderCell,
              isSelected: true,
              type: col?.type,
            };
          });

          const formatRows = (row, id) => {
            const metricsRows = Object.keys(row?.metrics_list || {}).reduce(
              (acc, curr) => {
                return {
                  ...acc,
                  [curr]: row?.metrics_list?.[curr]?.value ?? "-",
                  [`${curr}_compare`]:row?.metrics_list?.[curr]?.compared_value,
                  [`${curr}_compare_percent`]: row?.metrics_list?.[curr]?.compare_percent
                };
              },
              {}
            );

            const segmentsRows = Object.keys(row?.segment_list || {}).reduce(
              (acc, curr) => {
                return {
                  ...acc,
                  [curr]: row?.segment_list?.[curr]?.label ?? "-",
                  [curr + "_id"]: row?.segment_list?.[curr]?.value ?? "-",
                };
              },
              {}
            );

            const infoRows = Object.keys(row?.info_list || {}).reduce((acc,curr) => {
              const infoObject = row?.info_list?.[curr];
              return {
                ...acc,
                [curr]: data?.[infoObject?.attribute]?.[infoObject?.value]?.[infoObject?.info_path]
              }
            }, {})

            return {
              id,
              ...segmentsRows,
              ...infoRows,
              ...metricsRows,
              bid: row?.bid,
              budget: row?.budget,
              budget_spent: "-",
            };
          };

          const rowsToDisplay = data?.row_list?.map((row, ind) =>
            formatRows(row, ind + 1)
          );

          setColumns(mappedColumns);
          setLoading(false);
          setSelectedColumns(mappedColumns);
          setTableData([
            {
              [mappedColumns?.[0]?.accessorKey]: "TOTAL",
              ...formatRows(data?.total_row, "0"),
            },
            ...rowsToDisplay,
          ]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(campaign),
    token,
    account_id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(currentDateRange),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(comparitionDateRange),
    level,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(sortingState),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(segment_list),
    pageSize,
    pageIndex,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(hotel_id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(hotelList)
  ]);

  //@TODO pour la taille de la table
  const getMaxHeight = () => {
    if (isChartCollapsed) {
      return isPaginationEnabled
          ? "calc(100vh - 215px)"
          : "calc(100vh - 182px)";
    } else {
      return isPaginationEnabled
          ? "calc(100vh - 443px )"
          : "505px";
    }
  };

  return (
    <>
      <Grid sx={{ marginBlock: "10px", maxWidth: "100%", marginLeft: "4px" }}>
        <Table
          page="searchAds"
          rows={tableData}
          columns={columns}
          selectedColumns={selectedColumns}
          handleColumnSelectionChange={(columns) => {
            setSelectedColumns(columns);
          }}
          selectedSegments={segment_list}
          paginationData={paginationObject}
          isPaginationEnabled={isPaginationEnabled}
          isChartCollapsed={isChartCollapsed}
          setIsChartCollapsed={setIsChartCollapsed}
          maxHeight={getMaxHeight}
          onEditClick={() => {
            onEditClick();
          }}
          handlePagination={(pagination) => {
            setPageIndex(pagination?.pageIndex);
            setPageSize(pagination?.pageSize);
          }}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          handleSorting={(sorting) => {
            setSortingState(sorting);
          }}
          isLoading={loading}
        />
      </Grid>
    </>
  );
};

export default TableContainer;
