import {
  FilledCircleIcon,
  CircleIcon,
  SettingsIcon,
} from "../../../assets/images/Icons";
import { Box } from "@mui/material";
import SelectMenu from "./select_menu";
import { useEffect, useMemo, useRef, useState } from "react";
import "./chartContainer.scss";
import CustomSwitch from "../../../components/Switch";
import GraphOptions from "./graph_options";
import BiAxialLineChart from "../../../components/BiAxialLineChart";
import axios from "axios";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { createSearchParams } from "react-router-dom";
import { channelIds, getChartReportURLByPlatfrom } from "./utils";
import {getUserToken} from "../../../utils/functions/userToken";

export default function ChartContainer({hotelList = [], backgroundColor = "white" }) {
  const [menuOptions, setMenuOptions] = useState([]);
  const { platform = [] } = useSelector((state) => state.metaSearch);

  const token = getUserToken() || "";
  const [option1, setOption1] = useState( "noMetric");
  const [option2, setOption2] = useState("noMetric");
  const [option3, setOption3] = useState("noMetric");
  const [option4, setOption4] = useState("noMetric");
  const [compareOption1, setCompareOption1] = useState("noMetric");
  const [compareOption2, setCompareOption2] = useState("noMetric");
  const [compareOption3, setCompareOption3] = useState("noMetric");
  const [compareOption4, setCompareOption4] = useState("noMetric");

  const [apiChartData, setApiChartData] = useState({});
  const [apiComparisonChartData, setApiComparisonChartData] = useState({});
  const [chartData, setChartData] = useState([]);
  const [seriesData, setSeriesData] = useState({});
  const [seriesCompareData, setSeriesCompareData] = useState({});

  const filterRef = useRef(null);
  const [isChartCollapsed] = useState(false);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [filter, setFilter] = useState("daily");

  const { account_id = "", } = useSelector(
    (state) => state?.account?.account || {}
  );
  const {hotel_id = [], level, group_id = [] } = useSelector(state =>state?.account)
  const {
    currentDateRange = {},
    comparitionDateRange = {},
    compareWith,
  } = useSelector((state) => state?.calender);

  useEffect(() => {
    const params = {
      account_id,
      level,
      start_date: format(
        currentDateRange?.startDate || new Date(),
        "yyyy-MM-dd"
      ),
      end_date: format(currentDateRange?.endDate || new Date(), "yyyy-MM-dd"),
      ...(level === "HOTEL"
        ? { "hotel_id[]": hotel_id }
        : { "group_id[]": group_id }),
      ...(comparitionDateRange?.startDate && comparitionDateRange?.endDate
        ? {
            compare_start_date: format(
              comparitionDateRange?.startDate,
              "yyyy-MM-dd"
            ),
            compare_end_date: format(
              comparitionDateRange?.endDate,
              "yyyy-MM-dd"
            ),
          }
        : {}),
      ...(platform?.length > 1 ? (platform.every((pl) => pl.label.toLowerCase().includes("google"))
        ? {
            "google_channel[]": platform?.map((p) => channelIds?.[p?.id]),
          }
        : {
            "meta_channel[]": platform?.map((p) => channelIds?.[p?.id]),
          }): {}),
    };

    if (account_id && level && hotelList?.every(h => h?.account_id.toString() === account_id.toString())) {
      axios
        .post(`${getChartReportURLByPlatfrom(platform)}`, createSearchParams(
                params
            ),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const data = res?.data;
          const chartDataKeys = ["daily", "monthly", "weekly", "quarterly"];
          const comparisonChartDataKeys = [
            "daily_compare",
            "monthly_compare",
            "weekly_compare",
            "quarterly_compare",
          ];
          const options = data?.metrics?.map((m) => ({
            id: m.id,
            label: m.label,
          }));
          setMenuOptions(options || []);
          setSeriesData(data?.series?.date_series);
          setSeriesCompareData(data?.series?.date_series_compare);
          setOption1(options[1].id);
          setOption2(options[2].id);
          if(compareWith){
            setCompareOption1(options[1].id);
            setCompareOption2(options[2].id);
          }
          setApiChartData(
            Object.keys(data || {})
              .filter((key) => chartDataKeys.includes(key))
              .reduce((acc, curr) => {
                acc[curr] = data?.[curr];
                return acc;
              }, {})
          );
          setApiComparisonChartData(
            Object.keys(data || {})
              .filter((key) => comparisonChartDataKeys.includes(key))
              .reduce((acc, curr) => {
                acc[curr.split("_")[0]] = data?.[curr];
                return acc;
              }, {})
          );
        })
        .catch((err) => {
          console.error(err?.message);
        });
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(platform),
    account_id,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(currentDateRange),
      // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(comparitionDateRange),
    level,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(hotel_id),
      // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(group_id),
    compareWith,
  ]);

  useEffect(() => {
    const finalChartData = [];
    if (
      Object.keys(apiChartData).length &&
      Object.keys(apiComparisonChartData).length
    ) {
      console.log(
        (apiChartData || {})?.[filter],
        Object.entries((apiChartData || {})?.[filter])
      );

      Object.entries((apiChartData || {})?.[filter])?.[0]?.[1]?.forEach(
        (item, index) => {
          const lines = [option1, option2, option3, option4]
            .filter((op) => op !== "noMetric")
            ?.reduce((acc, i) => {
              const val = Number(apiChartData?.[filter]?.[i]?.[index]);
              acc = {
                ...acc,
                [i]: !Number.isNaN(val) ? val : undefined,
              }
              return acc;
            }, {});

          const lines2 = [
            compareOption1,
            compareOption2,
            compareOption3,
            compareOption4,
          ]
            .filter((op) => op !== "noMetric")
            ?.reduce((acc, i) => {
              const val = Number(
                apiComparisonChartData?.[filter]?.[i]?.[index]
              );
              const key = "compare" + i;
              acc = {
                ...acc,
                [key]: !Number.isNaN(val) ? val : undefined,
              }
              return acc;
            }, {});
          if (Object.values(lines).every((i) => i === undefined)) {
            return;
          }

          finalChartData.push({
            name: seriesData?.[filter][index],
            ...lines,
            ...lines2,
          });
        }
      );

      console.log(seriesData, finalChartData, "DDD");
      setChartData(finalChartData);
    }
  }, [
    apiChartData,
    apiComparisonChartData,
    filter,
    option1,
    option2,
    option3,
    option4,
    compareOption1,
    seriesData,
    compareOption2,
    compareOption3,
    compareOption4,
  ]);

  const getColors = useMemo(() => {
    const colors = ["#12794F", "#4470C1", "#EA9196", "#46508D"];
    [option1, option2, option3, option4]?.forEach((i, index) => {
      if (i === "noMetric") {
        colors[index] = null;
      }
    });

    return colors.filter((i) => i !== null);
  }, [option1, option2, option3, option4]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: backgroundColor,
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
              paddingTop: "22px",
          }}
        >
          {isChartCollapsed ? (
            <div className="quency-cf-normal title"> Graph </div>
          ) : (
            ""
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "flex-end",
              marginLeft: "auto",
            }}
          >
            <Box className="menu">
              <Box className="icon">
                {option1 === "noMetric" ? (
                  <CircleIcon />
                ) : (
                  <FilledCircleIcon color={"#12794F"} />
                )}
              </Box>

              <SelectMenu
                options={[
                  {
                    id: "noMetric",
                    label: "No metric",
                  },
                  ...menuOptions?.filter(
                    (item) =>
                      item?.id !== option2 &&
                      item?.id !== option3 &&
                      item?.id !== option4
                  ),
                ]}
                value={option1}
                handleChange={(v) => {
                  setOption1(v);
                  if (compareOption1 !== "noMetric") {
                    setCompareOption1(v);
                  }
                }}
                action={
                  compareWith && (
                    <div>
                      <Box sx={{ fontSize: 12 }}>
                        {" "}
                        <CustomSwitch
                          checked={compareOption1 !== "noMetric" && compareWith}
                          disabled={option1 === "noMetric"}
                          onChange={() => {
                            if (compareOption1 !== "noMetric") {
                              setCompareOption1("noMetric");
                            } else {
                              setCompareOption1(option1);
                            }
                          }}
                        />{" "}
                        Show Compare{" "}
                      </Box>
                    </div>
                  )
                }
              />
            </Box>
            <Box className="menu">
              <Box className="icon">
                {option2 === "noMetric" ? (
                  <CircleIcon />
                ) : (
                  <FilledCircleIcon color={"#4470C1"} />
                )}
              </Box>

              <SelectMenu
                options={[
                  {
                    id: "noMetric",
                    label: "No metric",
                  },
                  ...menuOptions?.filter(
                    (item) =>
                      item?.id !== option1 &&
                      item?.id !== option3 &&
                      item?.id !== option4
                  ),
                ]}
                value={option2}
                handleChange={(v) => {
                  setOption2(v);
                  if (compareOption2 !== "noMetric") {
                    setCompareOption2(v);
                  }
                }}
                action={
                  compareWith && (
                    <>
                      <Box sx={{ fontSize: 12 }}>
                        {" "}
                        <CustomSwitch
                          checked={compareOption2 !== "noMetric" && compareWith}
                          disabled={option2 === "noMetric"}
                          onChange={() => {
                            if (compareOption2 !== "noMetric") {
                              setCompareOption2("noMetric");
                            } else {
                              setCompareOption2(option2);
                            }
                          }}
                        />{" "}
                        Show Compare{" "}
                      </Box>
                    </>
                  )
                }
              />
            </Box>
            <Box className="menu">
              <Box className="icon">
                {option3 === "noMetric" ? (
                  <CircleIcon />
                ) : (
                  <FilledCircleIcon
                    color={option3 === "noMetric" ? "transparent" : "#EA9196"}
                  />
                )}
              </Box>

              <SelectMenu
                options={[
                  {
                    id: "noMetric",
                    label: "No metric",
                  },
                  ...menuOptions?.filter(
                    (item) =>
                      item?.id !== option1 &&
                      item?.id !== option2 &&
                      item?.id !== option4
                  ),
                ]}
                value={option3}
                handleChange={(v) => {
                  setOption3(v);
                  if (compareOption3 !== "noMetric") {
                    setCompareOption3(v);
                  }
                }}
                action={
                  compareWith && (
                    <>
                      <Box sx={{ fontSize: 12 }}>
                        {" "}
                        <CustomSwitch
                          checked={compareOption3 !== "noMetric" && compareWith}
                          disabled={option3 === "noMetric"}
                          onChange={() => {
                            if (compareOption3 !== "noMetric") {
                              setCompareOption3("noMetric");
                            } else {
                              setCompareOption3(option3);
                            }
                          }}
                        />{" "}
                        Show Compare{" "}
                      </Box>
                    </>
                  )
                }
              />
            </Box>
            <Box className="menu">
              <Box className="icon">
                {option4 === "noMetric" ? (
                  <CircleIcon />
                ) : (
                  <FilledCircleIcon
                    color={option4 === "noMetric" ? "transparent" : "#46508D"}
                  />
                )}
              </Box>

              <SelectMenu
                options={[
                  {
                    id: "noMetric",
                    label: "No metric",
                  },
                  ...menuOptions?.filter(
                    (item) =>
                      item?.id !== option2 &&
                      item?.id !== option3 &&
                      item?.id !== option1
                  ),
                ]}
                value={option4}
                handleChange={(v) => {
                  setOption4(v);
                  if (compareOption4 !== "noMetric") {
                    setCompareOption4(v);
                  }
                }}
                action={
                  compareWith && (
                    <>
                      <Box sx={{ fontSize: 12 }}>
                        {" "}
                        <CustomSwitch
                          checked={compareOption4 !== "noMetric" && compareWith}
                          disabled={option4 === "noMetric"}
                          onChange={() => {
                            if (compareOption4 !== "noMetric") {
                              setCompareOption4("noMetric");
                            } else {
                              setCompareOption4(option4);
                            }
                          }}
                        />{" "}
                        Show Compare{" "}
                      </Box>
                    </>
                  )
                }
              />
            </Box>

            <Box className="filters">
              <Box
                onClick={() => {
                  setIsFilterMenuOpen(!isFilterMenuOpen);
                }}
                ref={filterRef}
              >
                <SettingsIcon color="#12794F" />
                <GraphOptions
                  open={isFilterMenuOpen}
                  anchorEl={filterRef}
                  onClose={() => {
                    setIsFilterMenuOpen(false);
                  }}
                  defaultActive={filter}
                  onChange={(value) => {
                    setFilter(value);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "197px",
            display: isChartCollapsed ? "none" : "block",
          }}
        >
          <BiAxialLineChart
            data={chartData}
            colors={getColors}
            compareSeries={seriesCompareData?.[filter]}
          />
        </Box>
      </Box>
    </>
  );
}
