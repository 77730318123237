import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Switch,
  Snackbar,
  Alert, CircularProgress, Drawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./EditCampaignPanel.scss";
import { initialMonthlyBudget } from "../../../pages/searchAdsCampaign/utils/initialState";
import GoogleSearchAdsCampaignEditor from "../GoogleSearchAdsCampaignEditor";
import { APP_GOOGLE_SEM_API_ENDPOINT } from "constants/api_urls";
import {
  validateSettings,
  validateSettingsKeyword,
  validateAdsCreative,
} from "pages/searchAdsCampaign/utils/validators";
import { useSelector } from "react-redux";
import axios from "axios";
import { createSearchParams } from "react-router-dom";
import {getUserToken} from "../../../utils/functions/userToken";

const EditCampaignPanel = ({ campaignId, isOpen, onClose }) => {
  const token = getUserToken();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { hotel_id = [] } = useSelector(
    (state) => state?.account
  );
  const account = useSelector((state) => state?.account?.account);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("error");
  const [expandedPanel, setExpandedPanel] = useState("settings");
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [visitedPanels, setVisitedPanels] = useState(["settings"]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [targetValue, setTargetValue] = useState("");
  const [biddingStrategy, setBiddingStrategy] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [targetCpa, setTargetCpa] = useState("");
  const [targetRoas, setTargetRoas] = useState("");
  const [maxCpcLimit, setMaxCpcLimit] = useState("");
  const [impressionShare, setImpressionShare] = useState("");
  const [adLocation, setAdLocation] = useState("");
  const [monthlyBudget, setMonthlyBudget] = useState(initialMonthlyBudget);
  const [keywords, setKeywords] = useState([]);
  const [negativeKeywords, setNegativeKeywords] = useState([]);
  const [headlines, setHeadlines] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [siteLinks, setSiteLinks] = useState([]);
  const [callOuts, setCallOuts] = useState([]);
  const [structuredSnippets, setStructuredSnippets] = useState([]);
  const [finalURL, setFinalURL] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [calls, setCalls] = useState([
    { id: "default-call", phoneNumber: "", countryCode: "", irremovable: true },
  ]);
  const [displayPaths, setDisplayPaths] = useState([
    { id: "default-path-1", text: "", irremovable: true },
    { id: "default-path-2", text: "", irremovable: true },
  ]);
  const [dailyBudgetCap, setDailyBudgetCap] = useState(false);
  const [isCampaignPaused, setIsCampaignPaused] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("€");

  useEffect(() => {
    if (campaignId) {
      setDrawerOpen(true);
      axios
        .get(
          `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/get-campaign-details/?campaign_id=${campaignId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const bidValues = {
            MAXIMIZE_CONVERSIONS: setTargetCpa,
            MAXIMIZE_CONVERSION_VALUE: setTargetRoas,
            MAXIMIZE_CLICKS: setMaxCpcLimit,
            MANUAL_CPC: setMaxCpcLimit,
            MANUAL_CPC_ENHANCED: setMaxCpcLimit,
            TARGET_IMPRESSION_SHARE: setImpressionShare,
          };
          const data = response?.data;

          setIsCampaignPaused(data?.campaign_status !== "ENABLED");
          setCurrencySymbol(
              data?.currency_symbol ? data?.currency_symbol : "€"
          );
          setCampaignName(data?.campaign_name);
          setBiddingStrategy(data?.bidding_strategy?.bidding_strategy_option);
          bidValues[data?.bidding_strategy?.bidding_strategy_option](
            data?.bidding_strategy?.bidding_value
          );
          setTargetValue(data?.budget?.default_budget || 0);
          setDailyBudgetCap(data?.budget?.is_daily_budget);
          const monthlyBudgets = Object?.keys(data?.budget).reduce(
            (acc, curr, indx) => {
              if (curr.includes("budget_month")) {
                acc[Object.keys(monthlyBudget)[indx]] = data?.budget?.[curr];
              }
              return acc;
            },
            {}
          );
          setMonthlyBudget(monthlyBudgets);
          setSelectedLanguage(data?.campaign_lang);
          setSelectedCountries(data?.point_of_sales);
          setKeywords(
            data?.keywords?.map((key) => ({
              keyword: key?.keywordText,
              matchType: key?.matchType,
            })) || []
          );
          setNegativeKeywords(
            data?.keywords_negative?.map((key) => ({
              keyword: key?.keywordText,
              matchType: key?.matchType,
            })) || []
          );
          setFinalURL(data?.final_url?.url);
          setBusinessName(data?.business_name?.assetContent);
          setHeadlines(
            data?.headline?.map((headline) => ({
              text: headline?.assetContent,
            }))
          );
          setDescriptions(
            data?.description?.map((desc) => ({
              text: desc?.assetContent,
            }))
          );
          setSiteLinks(
            data?.site_link?.map((st) => ({
              sitelinkText: st?.siteLinkText,
              description1: st?.siteLinkDescription1,
              description2: st?.siteLinkDescript2,
              finalURL: st?.siteLinkFinalUrl,
            })) || []
          );
          setCallOuts(
            data?.callout?.map((cl) => ({
              text: cl?.assetContent,
            }))
          );
          setStructuredSnippets(
            data?.snippet?.map((cl) => ({
              text: cl?.assetContent,
            }))
          );
          setCalls([
            {
              id: calls?.[0].id,
              phoneNumber: String(data?.phone_call?.phoneNumber)?.trim(),
              countryCode: data?.phone_call?.phoneCountryCode,
              irremovable: true,
            },
          ]);
          setDisplayPaths(
            Object.keys(data?.display_path).map((key) => ({
              id: "display-path-1",
              text: data?.display_path?.[key],
              irremovable: true,
            }))
          );
        })
        .catch((err) => {
          console.error(err?.message);
        }).finally(() => {
          setDrawerOpen(false)
      });
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, token]);

  const handleToggle = (_, checked) => {
    setIsCampaignPaused((prev) => !prev);
    setDrawerOpen(true);
    const payload = {
      campaign_id: campaignId,
      campaign_status: checked ? "PAUSED" : "ENABLED",
    };
    axios
      .post(
        `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/edit-campaign/update-status/?${createSearchParams(
          payload
        )}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((_res) => {
        setToastType("success");
        setMessage("Campaign updated successfully!");
        setSnackbarOpen(true);
      })
      .catch((err) => {
        setToastType("error");
        setMessage(err?.message || "Something went wrong!");
        setSnackbarOpen(true);
      }).finally(() => {
        setDrawerOpen(false);
    });
  };

  const handleNext = (currentPanel) => {
    if (currentPanel === "settings") {
      setExpandedPanel("keywords");
      setVisitedPanels((prev) => [...prev, "keywords"]);
    } else if (currentPanel === "keywords") {
      setExpandedPanel("adsCreative");
      setVisitedPanels((prev) => [...prev, "adsCreative"]);
    } else if (currentPanel === "adsCreative") {
      handleUpdateAction();
    }
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleUpdateAction = () => {
    // Update action logic here
    if (
      validateSettings({
        selectedCountries,
        selectedLanguage,
        targetValue,
        campaignName,
        biddingStrategy,
        targetCpa,
        targetRoas,
        maxCpcLimit,
        impressionShare,
        adLocation,
        setMessage,
        setSnackbarOpen,
      }) &&
      validateSettingsKeyword({
        keywords,
        negativeKeywords,
        setMessage,
        setSnackbarOpen,
      }) &&
      validateAdsCreative({
        finalURL,
        businessName,
        headlines,
        descriptions,
        siteLinks,
        callOuts,
        structuredSnippets,
        calls,
        displayPaths,
        setMessage,
        setSnackbarOpen,
      })
    ) {
      setDrawerOpen(true);
      const bidValues = {
        MAXIMIZE_CONVERSIONS: targetCpa,
        MAXIMIZE_CONVERSION_VALUE: targetRoas,
        MAXIMIZE_CLICKS: maxCpcLimit,
        MANUAL_CPC: maxCpcLimit,
        MANUAL_CPC_ENHANCED: maxCpcLimit,
        TARGET_IMPRESSION_SHARE: impressionShare,
      };
      const payload = {
        account_id: account?.account_id,
        hotel_id: hotel_id?.[0],
        campaign_settings: {
          campaign_type: selectedObjective,
          campaign_name: campaignName,
          bidding_strategy: {
            bidding_strategy_option: biddingStrategy,
            bidding_value: bidValues?.[biddingStrategy],
            bidding_max_value:
              biddingStrategy === "TARGET_IMPRESSION_SHARE"
                ? maxCpcLimit
                : undefined,
            ad_location: adLocation ?? undefined,
          },
          budget: {
            default_budget: Number(targetValue),
            is_daily_budget: dailyBudgetCap,
            ...Object.keys(monthlyBudget).reduce((acc, curr, index) => {
              acc[`budget_month_${index + 1}`] = Number(monthlyBudget[curr]);
              return acc;
            }, {}),
          },
          campaign_lang: selectedLanguage,
          point_of_sales: selectedCountries,
        },
        campaign_ads_creative: {
          final_url: { url: finalURL },
          business_name: {
            assetContent: businessName,
          },
          headline: headlines.map((h) => ({ assetContent: h.text })),
          description: descriptions.map((d) => ({ assetContent: d.text })),
          site_link: siteLinks.map((sitelink) => ({
            siteLinkText: sitelink?.sitelinkText,
            siteLinkDescription1: sitelink?.description1,
            siteLinkDescription2: sitelink?.description2,
            siteLinkFinalUrl: sitelink?.finalURL,
          })),
          callout: callOuts.map((callout) => ({ assetContent: callout?.text })),
          snippet: structuredSnippets.map((snippet) => ({
            assetContent: snippet?.text,
          })),
          phone_call: {
            phoneCountryCode: calls?.[0].countryCode,
            phoneNumber: calls?.[0].phoneNumber,
          },
          display_path: displayPaths.reduce((acc, curr) => {
            acc[curr.id] = curr.text;
            return acc;
          }, {}),
        },
        campaign_keywords: {
          keywords: keywords.map((keyword) => ({
            keywordText: keyword.keyword,
            matchType: keyword?.matchType,
          })),
          keywords_negative: negativeKeywords.map((keyword) => ({
            keywordText: keyword.keyword,
            matchType: keyword?.matchType,
          })),
        },
      };

      axios
        .put(
          `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/add-campaign/`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((_res) => {
          setToastType("success");
          setMessage("Campaign updated successfully!");
          setSnackbarOpen(true);
        })
        .catch((err) => {
          setToastType("error");
          setMessage(err?.message || "Something went wrong!");
          setSnackbarOpen(true);
        }).finally(() => {
        setDrawerOpen(false);
      });
    }
  };

  return (
    <>
      {isOpen && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1100,
          }}
        />
      )}
      <Box className={`editCampaignPanel ${isOpen ? "open" : ""}`}>
        <Box
          className="header"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={onClose} className="closeButton">
              <CloseIcon fontSize="small" />
            </IconButton>
            <Typography variant="body1" className="title">
              Edit Campaign
            </Typography>
          </Box>
          {/* Toggle Button */}
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Switch
              checked={isCampaignPaused}
              onChange={handleToggle}
              color="primary"
              inputProps={{ "aria-label": "Pause Campaign Toggle" }}
            />
            <Typography variant="body2" className="pause-toggle-title">
              Pause Campaign
            </Typography>
          </Box>
        </Box>
        <Box className="content">
          <GoogleSearchAdsCampaignEditor
            onClose={onClose}
            expandedPanel={expandedPanel}
            setExpandedPanel={setExpandedPanel}
            selectedObjective={selectedObjective}
            setSelectedObjective={setSelectedObjective}
            visitedPanels={visitedPanels}
            handleNext={handleNext}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            targetValue={targetValue}
            setTargetValue={setTargetValue}
            biddingStrategy={biddingStrategy}
            setBiddingStrategy={setBiddingStrategy}
            campaignName={campaignName}
            setCampaignName={setCampaignName}
            targetCpa={targetCpa}
            setTargetCpa={setTargetCpa}
            targetRoas={targetRoas}
            setTargetRoas={setTargetRoas}
            maxCpcLimit={maxCpcLimit}
            setMaxCpcLimit={setMaxCpcLimit}
            impressionShare={impressionShare}
            setImpressionShare={setImpressionShare}
            adLocation={adLocation}
            setAdLocation={setAdLocation}
            monthlyBudget={monthlyBudget}
            setMonthlyBudget={setMonthlyBudget}
            keywords={keywords}
            setKeywords={setKeywords}
            negativeKeywords={negativeKeywords}
            setNegativeKeywords={setNegativeKeywords}
            headlines={headlines}
            setHeadlines={setHeadlines}
            descriptions={descriptions}
            setDescriptions={setDescriptions}
            siteLinks={siteLinks}
            setSiteLinks={setSiteLinks}
            callOuts={callOuts}
            setCallOuts={setCallOuts}
            structuredSnippets={structuredSnippets}
            setStructuredSnippets={setStructuredSnippets}
            finalURL={finalURL}
            setFinalURL={setFinalURL}
            businessName={businessName}
            setBusinessName={setBusinessName}
            calls={calls}
            setCalls={setCalls}
            displayPaths={displayPaths}
            setDisplayPaths={setDisplayPaths}
            dailyBudgetCap={dailyBudgetCap}
            setDailyBudgetCap={setDailyBudgetCap}
            currencySymbol={currencySymbol}
            campaignId={campaignId}
          />
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={7000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={toastType}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
        <Drawer
            anchor="top"
            open={drawerOpen}
            PaperProps={{
              sx: {
                background: "transparent"
              },
            }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <CircularProgress size={60} color="primary" />
          </Box>
        </Drawer>
      </Box>
    </>
  );
};

export default EditCampaignPanel;
