import React from "react";
import {Grid, Box, Select} from "@mui/material";
import { TopMarketsAvailableMetrics } from "../../Utils/AvailableMetrics";
import MenuItem from "@mui/material/MenuItem";
import {TopMarketsOptions} from "../../Utils/Options";
import { formatValue } from "features/new-homepage/Utils/FormatMetricValue";

function TopMarketsTable({
    marketOption,
    selectedMarkets,
    topMarketsMetrics,
    selectedOption,
    handleMarketOptionChange,
    handleDropdownChange,
}) {

    console.log(marketOption,"OptionMarket");
    
    return (
        <Grid item xs={12} className="top-markets-table-container">
            <Grid container>
                <Grid item xs={12}>
                    <Box className="top-markets-table-header">
                        <Box className="option-container">
                            <Select
                                value={selectedOption}
                                onChange={handleDropdownChange}
                                variant="outlined"
                                displayEmpty
                                sx={{
                                    color: "black",
                                    fontSize: "12px",
                                    padding: "0",
                                    border: "none",
                                    height: "28px",
                                    fontWeight: 600,
                                    background: "transparent",
                                    display: "flex",
                                    fontFamily: "Inter",
                                    "& .MuiSelect-icon": {
                                        color: "#BAB7B7",
                                    },
                                    "& .MuiSelect-select": {
                                        padding: 0
                                    },
                                    "& fieldset": {
                                        border: "none",
                                        padding: 0
                                    }
                                }}
                            >
                                {TopMarketsOptions.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        sx={{
                                            fontSize: "12px",
                                            padding: "4px 8px",
                                            lineHeight: "1.2",
                                            fontFamily: "Inter"
                                        }}
                                    >
                                        {option.key}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Select
                                value={marketOption}
                                onChange={(e) => handleMarketOptionChange(e.target.value)}
                                variant="outlined"
                                displayEmpty
                                sx={{
                                    color: "black",
                                    fontSize: "12px",
                                    padding: "0",
                                    border: "none",
                                    fontWeight: 600,
                                    height: "28px",
                                    background: "transparent",
                                    display: "flex",
                                    fontFamily: "Inter",
                                    "& .MuiSelect-icon": {
                                        color: "#BAB7B7",
                                    },
                                    "& .MuiSelect-select": {
                                        padding: 0
                                    },
                                    "& fieldset": {
                                        border: "none",
                                        padding: 0
                                    }
                                }}
                            >
                                {Object.entries(TopMarketsAvailableMetrics).map(([key, label]) => (
                                    <MenuItem
                                        key={key}
                                        value={key}
                                        sx={{
                                            fontSize: "12px",
                                            padding: "4px 8px",
                                            lineHeight: "1.2",
                                            fontFamily: "Inter"
                                        }}
                                    >
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {/* First 5 Markets */}
                        <Grid item xs={6} className="top-markets-table">
                            {selectedMarkets?.slice(0, 5).length > 0 ? (
                                selectedMarkets.slice(0, 5).map((market, index) => (
                                    <Box key={`market-${index}`} className="market-row">
                                        <Box className="market-name" key={`market-${index}-name`}>
                                            {market.market_name}
                                        </Box>
                                        <Box className="market-value" key={`market-${index}-value`}>
                                        {formatValue(`${marketOption}_kpi`,market,topMarketsMetrics.currency_symbol)}
                                        </Box>
                                    </Box>
                                ))
                            ) : (
                                <Box className="market-row no-data">No Data Available</Box>
                            )}
                        </Grid>

                        {/* Last 5 Markets */}
                        <Grid item xs={6} className="top-markets-table">
                            {selectedMarkets?.slice(-5).map((market, index) => (
                                <Box key={`market-${index}`} className="market-row">
                                    <Box className="market-name" key={`market-${index}-name`}>
                                        {market.market_name}
                                    </Box>
                                    <Box className="market-value" key={`market-${index}-value`}>
                                        {formatValue(`${marketOption}_kpi`,market,topMarketsMetrics.currency_symbol)}
                                    </Box>
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default TopMarketsTable;
